import React, {FC, lazy, memo, Suspense} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkHeader from "../../components/particles/TkHeader";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {tk_route_account} from "../TkMyAccountView";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import './style.scss'

const MyCompanies = lazy(() => import('./_myCompanies'))

export const tk_route_companies = '/sua-conta/empresas';

const TkMyCompaniesView: FC = () => {

    return <>
        <TkHeadSEO title='Empresas | Plenobras'/>

        <TkHeader/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_account,
                    label: 'Sua Conta'
                }, {
                    url: tk_route_companies,
                    label: 'Empresas'
                }
            ]}/>

            <h1>Empresas</h1>

            <Suspense fallback={<div>Carregando...</div>}>
                <MyCompanies/>
            </Suspense>
        </div>
        <TkNewsLetter/>
        <TkFooter/>
    </>
}

export default memo(TkMyCompaniesView)