import {Dispatch, SetStateAction, useEffect, useState} from "react";

export interface TkBottomSheetContextType {
    isShow: boolean;
    setShow: Dispatch<SetStateAction<boolean>>
}

const TkBottomSheetContext = (): TkBottomSheetContextType => {
    const [isShow, setShow] = useState(false);

    useEffect(() => {
        document.body.classList[isShow ? 'add' : 'remove']('o-h');
    }, [isShow]);

    return {isShow, setShow};
};

export default TkBottomSheetContext
