import React, {FC, memo} from "react";

import {MAX_DROPDOWN_QUANTITY} from "../../../utils/constants"

const TkQuantityOptions: FC<{
    stock?: number
    isForceAppendMore?:boolean
    minimumSaleQuantity?: number
    controlMultiplicity?: boolean
    isLeadTime?: boolean
}> = ({stock, isForceAppendMore = false, minimumSaleQuantity = 1, controlMultiplicity = false, isLeadTime = false}) => {

    const step = controlMultiplicity ? minimumSaleQuantity : 1
    const maxProducts = 2500 * step
    const isGreaterThan = (stock%step) > 30
    
    if(isLeadTime) {
        isForceAppendMore = true
        if(stock<step) stock = step * 30
    }

    const items = Array((stock > maxProducts ? maxProducts : stock) || MAX_DROPDOWN_QUANTITY)
        .fill(0)
        .filter((_, idx) => (idx + 1) % step === 0);

    return <>
        {(isGreaterThan || isForceAppendMore) && <option value='more'>Outro</option>}
        {items.map((item, idx) => <option key={idx} value={(idx + 1)*step}>Qtd: {(idx + 1)*step}</option>)}
    </>
};

export default memo(TkQuantityOptions)
