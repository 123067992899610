import React, {FC, memo, useCallback} from "react";
import {tk_route_root} from "../../../views/TkHomeView";
import {Link} from "react-router-dom";
import {isBlank} from "../../../utils/string-utils";
import './style.scss'
import LogoPleno from "../../../assets/images/logo-loja-plenobras-colored.svg";

const TkLogoVector: FC<{
    className?: string
}> = ({className}) => {

    return <img src={LogoPleno} alt="Logo plenobras" className={className}/>
};

export enum LogoMode {
    one
}

export const TkLogoBuilder:FC<{
    to?: string
    title?: string
    mode?: LogoMode
}> = memo(({to = tk_route_root, title = 'Voltar à tela inicial', mode = LogoMode.one}) => {

    const getMode = useCallback(() => {
        const clazz = ['TkLogoVector']
        switch (mode) {
            case LogoMode.one:
                clazz.push('__mode-one')
                break
        }


        return clazz.join('')
    }, [])

    if (isBlank(to)) return <TkLogoVector className={getMode()}/>

    return <Link to={to} title={title}>
        <TkLogoVector className={getMode()}/>
    </Link>
})

export default memo(TkLogoVector);
