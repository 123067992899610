import React, {FC, useContext, useState} from "react";
import TkLazyImage from "../../components/particles/TkLazyImage";
import {Link} from "react-router-dom";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import _ListContext from "./_listContext";
import {ITkProductModel} from "../../models/product";
import {tk_route_search} from "../TkSearchView";
import {formatMoney} from "../../utils/number-utils";
import NoPhoto from "../../assets/images/no-photo-01.svg";
import TkQuantitySelect from "../../components/cart/TkQuantitySelect";

const _PickupProductCard:FC<{
    product: ITkProductModel
}> = ({product}) => {
    const {isProductInList, productQuantityInList, updateProductInList, removeProductFromList, isUpdatingProductInList} = useContext(_ListContext)
    const [quantity, setQuantity] = useState(productQuantityInList(product._id) || 0)

    return <div className='TkSellerListManagerView__card'>
        <TkLazyImage src={product.thumbnail}
                     plainB={NoPhoto}
                     alt={product.name}
                     className='img-responsive'/>
        <div className='TkSellerListManagerView__card-body'>
            <h1 className='TkSellerListManagerView__title ellipsis'>{product.name}</h1>
            <span className='TkSellerListManagerView__brand'>por <Link to={tk_route_search}>ABB</Link></span><br/>
            <span className='TkSellerListManagerView__price'>{formatMoney(product.promoPrice || product.price)} <span className='TkSellerListManagerView__unit'>por {product.unitCommercialName.toLowerCase()}</span></span><br/>
            <span>
                {(product.stock || product.leadTime) && product.price? (
                    product.stock ?
                        <p className="TkSellerListManagerView__card-availability">Em Estoque</p>
                        : <p className="TkSellerListManagerView__card-backorder">Sob encomenda</p>
                ) : (
                    <p className="TkSellerListManagerView__card-out-of-stock">Indisponível</p>
                )
                }

            </span>
        </div>
        <div className='TkSellerListManagerView__card-actions'>
            <div>
                <TkQuantitySelect
                    onChange={setQuantity}
                    product={product}
                    disabled={isUpdatingProductInList(product._id)}
                    initialQuantity={quantity}
                />
                <button type='button'
                        disabled={isUpdatingProductInList(product._id) || quantity === 0}
                        onClick={() => updateProductInList(product._id, quantity)}
                        className='button-2 d-flex-i a-i-c m-r-1em'>
                    <TkSvgIcon
                        style={{margin: 0}}
                        className={isUpdatingProductInList(product._id) ? 'rotate-1-seg' : ''}
                        icon={isUpdatingProductInList(product._id) ? 'sync-solid' : 'pencil-edit-solid'}
                    /> Atualizar
                </button>
                <button type='button'
                        disabled={isUpdatingProductInList(product._id) || !isProductInList(product._id)}
                        onClick={() => {
                            removeProductFromList(product._id)
                            setQuantity(0)
                        }}
                        className='button-5 d-flex-i a-i-c m-r-1em'>
                    <TkSvgIcon
                        className={isUpdatingProductInList(product._id) ? 'rotate-1-seg' : ''}
                        style={{margin: 0}}
                        icon={isUpdatingProductInList(product._id) ? 'sync-solid' : 'trash-alt'}
                    /> Remover
                </button>
            </div>
        </div>
    </div>
}


export default _PickupProductCard