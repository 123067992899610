import React, {FC, memo, useCallback, useState} from "react"
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import {TkLogoBuilder} from "../../components/particles/TkLogoVector";
import {cardsContent, getIcon, getTitle} from "./_utils";
import {animated as a, useTrail} from "react-spring";
import TkDivider from "../../components/particles/TkDivider";
import {tk_var_teky_address1, tk_var_teky_document, tk_var_teky_name} from "../TkBeInTouchView";
import {formatDocument, isEmail} from "../../utils/string-utils";
import {useHistory, useLocation} from "react-router-dom";
import {AccountType} from "../../models/user";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import DeviceStorage from "../../utils/storage-utils";
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import {useTkAppErrors, useTkUser} from "../../context/TkContext";


const _ChooseEmailAccount:FC = () => {
    const history = useHistory()
    const {search, pathname} = useLocation()
    const p = new URLSearchParams(search)
    const type: AccountType = AccountType[p.get('tipo') as keyof typeof AccountType]
    const cc = cardsContent.filter(cc => cc.type === type)[0];
    const [isCheckingPlan, setCheckingPlan] = useState(false)
    const [isDoing, setDoing] = useState(false)
    const [errors, setErrors] = useState([])
    const [migrateErrors, setMigrateErrors] = useState([])
    const {bind: bindEmail, value: emailValue, setValue: setEmail} = useSimpleInput('');
    const {getPlan, migratePlan, userAuth} = useTkUser()
    const {goToPageError} = useTkAppErrors()

    const config = {mass: 5, tension: 2000, friction: 200}
    const trail = useTrail(2, {
        config,
        from: {
            opacity: 0,
            transform: `translate3d(40px,0,0)`
        },
        to: {
            opacity: 1,
            transform: 'translate3d(0,0,0)'
        }
    });

    const trailErrors = useTrail(errors.length, {
        config,
        opacity: 1,
        from: { opacity: 0 },
    });

    const trailMigrateErrors = useTrail(migrateErrors.length, {
        config,
        opacity: 1,
        from: { opacity: 0 },
    });

    const validateEmail = useCallback(async () => {
        const p = new URLSearchParams(search)

        setCheckingPlan(true)
        setErrors([])

        try {
            if (isEmail(emailValue)) {
                const user = await getPlan(emailValue);

                if (user) {
                    setErrors(['E-mail já cadastrado'])
                } else {
                    DeviceStorage.putEmailSignup(emailValue)
                    p.set('estagio', 'criar')
                }
                history.push(`${pathname}?${p.toString()}`)
            } else {
                setErrors(['E-mail inválido'])
            }

        } catch (e) {
            goToPageError(e);
        } finally {
            setCheckingPlan(false)
        }
    }, [emailValue, setCheckingPlan, setErrors])

    const doMigratePlan = useCallback(async () => {
        setDoing(true)
        setMigrateErrors([])
        try {
            const {errorMessages} = await migratePlan(type);

            if (errorMessages) {
                setMigrateErrors(errorMessages.map((em: any) => em.message))
            } else {
                DeviceStorage.destroyEmailSignup()

                p.set('estagio', 'completar-dados')

                history.replace(`${pathname}?${p.toString()}`)
            }
        } catch (e) {
            goToPageError(e)
        } finally {
            setDoing(false)
        }
    }, [userAuth, setDoing, setMigrateErrors, goToPageError])

    return <>
        <TkHeadSEO title='Converta sua conta ou escolha outro e-mail | Plenobras' description='Deseja criar uma nova conta ou converter a atual'/>

        <div className="container m-t-1rem t-a-c">
            <TkLogoBuilder/>

            <div className='TkSignUpView__container-conv m-t-40px'>
                {trail.map((props, index) => {
                    return <a.article style={props}
                                      key={index}
                                      className={`TkSignUpView__card-conv ${type}`}>
                        <header className={`${index === 0 ? 'left' : 'right'}`}>
                            <TkSvgIcon icon={getIcon(type)}/>
                            <h2>{index === 0 ? 'Converter' : 'Nova'} Conta Plenobras {getTitle(type)}</h2>
                        </header>
                        <div className='content'>
                            <ul>
                                {cc.defaultFeatures.map(df => {
                                    return <li key={type + df}>
                                        <TkSvgIcon icon='check-solid' className='TkSignUpView__icon-size-1'/><span>{df}</span>
                                    </li>
                                })}
                            </ul>
                            {cc.plusFeatures && <div className='t-a-l m-t-20px'>
                                <div className='m-b-20px plus-more'>
                                    <TkSvgIcon icon='plus-solid' className='TkSignUpView__icon-size-1'/>&nbsp;&nbsp;&nbsp;e mais
                                </div>
                                <ul>
                                    {cc.plusFeatures.map(pf => {
                                        return <li key={type + pf}>
                                            <TkSvgIcon icon='check-solid' className='TkSignUpView__icon-size-1'/><span>{pf}</span>
                                        </li>
                                    })}
                                </ul>
                            </div>}
                        </div>

                        {index === 0 ?
                            <>
                                <div style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    paddingBottom: 20,
                                }}>
                                    {trailMigrateErrors.map((props, index) => (
                                        <a.div
                                            key={`${index}__`}
                                            className='alert alert-sm alert-danger m-t-20px'
                                            style={props}>
                                            {migrateErrors[index]}
                                        </a.div>
                                    ))}
                                </div>

                                <button
                                    type='button'
                                    title={`Migrar ${cc.title}`}
                                    disabled={isDoing}
                                    onClick={doMigratePlan}
                                    className={`TkSignUpView__plan-button TkSignUpView__plan-button-${type}`}>
                                    <TkSvgIcon icon={isDoing ? 'sync-solid' : getIcon(type)}
                                               className={`m-r-1em ${isDoing ? 'rotate-1-seg' : ''}`}/> Converter a conta</button>
                            </>
                            :
                            <div className='t-a-l'
                                 style={{
                                paddingLeft: '2em',
                                paddingRight: '2em',
                                marginBottom: '2em'
                            }}>
                                <small>Insira os dados para criar nova conta:</small><br/>
                                <label htmlFor="anotherEmailSignUp">Novo E-mail <span style={{
                                    color: 'red'
                                }}>*</span></label>
                                <input type="email"
                                       id='anotherEmailSignUp'
                                       disabled={isCheckingPlan}
                                       className='form-control form-control-sm' {...bindEmail}/>

                                {trailErrors.map((props, index) => (
                                    <a.div
                                        key={index}
                                        className='alert alert-sm alert-danger m-t-20px'
                                        style={props}>
                                        {errors[index]}
                                    </a.div>
                                ))}

                                <button
                                    type='button'
                                    title={`Criar ${cc.title}`}
                                    disabled={isCheckingPlan}
                                    onClick={validateEmail}
                                    className={`TkSignUpView__plan-button TkSignUpView__plan-button-${type}`}>
                                    <TkSvgIcon icon={isCheckingPlan ? 'sync-solid' : getIcon(type)}
                                               className={`m-r-1em ${isCheckingPlan ? 'rotate-1-seg' : ''}`}/> Criar nova conta</button>
                            </div>
                        }
                    </a.article>
                })}
            </div>

            <TkDivider style={{marginTop: 50}}/>

            <div className='TkSignUpView__footer'>
                <p>{tk_var_teky_name} | {formatDocument(tk_var_teky_document)} | {tk_var_teky_address1}</p>
                <p>Formas de Pagamento: Cartão de crédito, Boleto e Pix</p>
            </div>
        </div>
    </>
}


export default memo(_ChooseEmailAccount)
