import {TkCustomerDeakType} from "../../models/order";
import {useCallback, useReducer} from "react";
import {httpPostGraphQL} from "../../utils/http-utils";
import {makeOrderFromDeakSellerMutation, searchCustomerPlenobrasSellerOrderQuery} from "./queries";
import TkDeakReducer, {TkDeakReducerType, TkDeakState} from "./reducer";
import {List, TkProductListDeakPriceType} from "../../models/list";

export interface TkDeakContextType {
    searchCustomerPlenobrasSellerOrder: (document: string) => Promise<TkCustomerDeakType>
    setCustomer: (customer: TkCustomerDeakType) => void
    customer: TkCustomerDeakType
    list: List
    isLoadingList: boolean
    setLoadingList: (isLoading: boolean) => void
    setList: (list: List) => void
    prices?: TkProductListDeakPriceType
    setPrices: (prices: TkProductListDeakPriceType) => void
    makeOrder: (sellerName: string) => Promise<void>
    isMakingOrder: boolean
    resetState: () => void
    setShipmentCost: (shipmentCost: string) => void
}

const TkDeakContext = (): TkDeakContextType => {
    const [state, dispatch] = useReducer(TkDeakReducer, {} as TkDeakState)

    function setCustomer(customer: TkCustomerDeakType) {
        dispatch({type: TkDeakReducerType.setCustomer, payload: customer})
    }

    function setList(list: List) {
        dispatch({type: TkDeakReducerType.setList, payload: list})
    }

    function setIsLoadingList(isLoading: boolean) {
        dispatch({type: TkDeakReducerType.setIsLoadingList, payload: isLoading})
    }

    function setPrices(prices: TkProductListDeakPriceType) {
        dispatch({type: TkDeakReducerType.setPrices, payload: prices})
    }

    function setShipmentCost(shipmentCost: string) {
        dispatch({type: TkDeakReducerType.setShipmentCost, payload: shipmentCost})
    }

    function setIsMakingOrder(isMakingOrder: boolean) {
        dispatch({type: TkDeakReducerType.setIsMakingOrder, payload: isMakingOrder})
    }

    function resetState() {
        dispatch({type: TkDeakReducerType.clearProcess})
    }

    const searchCustomerPlenobrasSellerOrder = useCallback(async (document: string): Promise<TkCustomerDeakType> => {
        try {
            const {data: result} = await httpPostGraphQL({
                query: searchCustomerPlenobrasSellerOrderQuery,
                variables: {document}
            });

            if (result.errors) return Promise.reject(result.errors);
            else {
                const {data: {searchCustomerPlenobrasSellerOrder}} = result;
                return Promise.resolve(searchCustomerPlenobrasSellerOrder)
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }, []);

    const makeOrder = useCallback(async (sellerName: string) => {
        setIsMakingOrder(true)

        try {
            if(!state.prices) state.prices = {}

            const deakOrder: any = {
                sellerName,
                listId: state.list._id,
                customer: {
                    name: state.customer.name,
                    document: state.customer.document,
                    email: state.customer.email,
                    phone: state.customer.phone,
                    address: {
                        location: state.customer.address.location,
                        locationNumber: state.customer.address.locationNumber,
                        locationNotes: state.customer.address.locationNotes,
                        postalCode: state.customer.address.postalCode,
                        neighborhoodName: state.customer.address.neighborhoodName,
                        cityName: state.customer.address.cityName,
                        stateAcronym: state.customer.address.stateAcronym
                    }
                },
                shipmentCost: parseFloat(state.shipmentCost),
                productPrices: Object.keys(state.prices).map((key) => ({
                    _id: key,
                    price: state.prices[key]
                }))
            }


            const {data: result} = await httpPostGraphQL({
                query: makeOrderFromDeakSellerMutation,
                variables: {deakOrder}
            });

            if (result.errors) return Promise.reject(result.errors);
            else {
                const {data: {makeOrderFromDeakSeller}} = result;
                return Promise.resolve(makeOrderFromDeakSeller)
            }
        } catch (e) {
            console.error('Falha ao realizar pedido deak', e)
            return Promise.reject(e);
        } finally {
            setIsMakingOrder(false)
        }

    }, [state])

    return {
        customer: state.customer,
        list: state.list,
        isLoadingList: state.isLoadingList,
        prices: state.prices,
        isMakingOrder: state.isMakingOrder,
        searchCustomerPlenobrasSellerOrder,
        setCustomer,
        setList,
        setLoadingList: setIsLoadingList,
        setPrices,
        makeOrder,
        resetState,
        setShipmentCost
    }
}

export default TkDeakContext