import {
    ITkClassModel,
    ITkDivisionModel,
    ITkFamilyModel,
    ITkGroupModel,
    ITkManufacturerModel,
    ITkProductModel,
    TTkBestClassesResult,
    TTkBestManufacturersResult,
    TTkBestProductsCategoriesResult
} from "../../models/product";
import {useCallback, useEffect, useReducer} from "react";
import TkProductReducer, {ProductReducerActionType, ProductReducerType} from "./reducer";
import {sendException} from "../../utils/analytics-utils";
import {httpPostGraphQL} from "../../utils/http-utils";
import {
    autoCompleteQuery,
    bestClassesQuery,
    bestProductsCategoriesQuery,
    bestProductsManufacturersQuery,
    classifiersQuery,
    productDetailQuery,
    productSearchQuery,
    productsOutdoor,
    relatedProducts, similarityProductsQuery
} from "./queries";
import {capitalize, isBlank} from "../../utils/string-utils";
import {isSomething} from "../../utils/utils";

interface TotalResult {
    total: number
}

export interface ProductListResult extends TotalResult {
    items: ITkProductModel[]
}

export interface AutoCompleteSuggestion {
    type?: string
    text?: string
}
export interface AutoCompleteResult extends TotalResult {
    suggestions: AutoCompleteSuggestion[]
}


export interface ProductSearchCriteria {
    term?: string,
    divisionId?: string,
    familyIds?: string[],
    classIds?: string[],
    groupIds?: string[],
    manufacturerIds?: string[],
    stars?: number[] | number,
    from?: number,
    pageSize?: number,
    priceStart?: number,
    priceEnd?: number,
    nameOrder?: string,
    priceOrder?: string,
    showAvailableStock?: string,
    showPromotions?: string
}

interface ClassifiersResult {
    manufacturers?: ITkManufacturerModel[]
    divisions?: ITkDivisionModel[]
    families?: ITkFamilyModel[]
    classes?: ITkClassModel[]
    groups?: ITkGroupModel[]
}

export enum ClassifiersType {
    MANUFACTURERS = 'manufacturers',
    DIVISIONS = 'divisions',
    FAMILIES = 'families',
    CLASSES = 'classes',
    GROUPS = 'groups'
}

export interface TkProductContextType {
    getProductsOutdoor: (name: string, pageSize?: number) => Promise<ProductListResult | undefined | null>
    getBestClasses: () => Promise<TTkBestClassesResult[] | undefined | null>
    getBestProductsManufacturers: (..._ids: string[]) => Promise<TTkBestManufacturersResult[] | undefined | null>
    getBestProductsCategories: (divisionsIds: string[], familiesIds: string[], classesIds: string[]) => Promise<TTkBestProductsCategoriesResult[] | undefined | null>
    getRelatedProducts: (mainName: string, divisionId: string, pageSize?: number) => Promise<ProductListResult | undefined | null>
    getSimilarityProducts: (externalId: string) => Promise<ITkProductModel[] | undefined | null>
    productSearch: (criteria?: ProductSearchCriteria) => Promise<ProductListResult | undefined | null>
    loadClassifiers: (...classifiers: ClassifiersType[]) => Promise<ClassifiersResult>;
    getProductDetails: (_id: string) => Promise<ITkProductModel | undefined | null>
    autoComplete: (text: string, divisionId?: string) => Promise<AutoCompleteResult | undefined | null>
    state: ProductReducerType
}

let bestClassesCache: TTkBestClassesResult[] = []
let bestProductsManufacturersCache: TTkBestManufacturersResult[] = []
let bestProductsCategoriesCache: TTkBestProductsCategoriesResult[] = []
let similarityProductsCache: {
    [key:string]: ITkProductModel[]
} = {}

type KeyCache = {
    [key: string]: ProductListResult
}

let productPromotionsCache: KeyCache = {}

/**
 * Detecta o ícone pelo id da divisão
 */
const bindDivisionIcon: any = {
    'EPIs e EPCs': 'epis-e-epcs',
    'Fixação e Vedação': 'fixacao-e-vedacao',
    'Outros': 'outros',
    'Elétrica e Telecom': 'eletrica-e-telecom',
    'Motores e Bombas': 'motores-e-bombas',
    'Hidráulicos e Pneumáticos': 'hidraulicos-e-pneumaticos',
    'Ferramentas e Máquinas': 'ferramentas-e-maquinas',
    'Gás e Incêndio': 'gas-e-incendio',
    'Iluminação': 'iluminacao',
    'Automação': 'automacao',
    'Rolamentos e Mecânica': 'rolamentos-e-mecanica',
    'Energia Solar': 'energia-solar',
    'Fios e Cabos': 'fios-e-cabos',
}

const TkProductContext = (): TkProductContextType => {
    const [state, dispatch] = useReducer(TkProductReducer, {
        isLoadingClassifiers: false,
        divisions: [],
        manufacturers: [],
        families: [],
        classes: [],
        groups: []
    });

    const getProductsOutdoor = useCallback(async (name: string, pageSize?: number): Promise<ProductListResult | undefined | null> => {
        const key = `${name}-${pageSize || 10}`
        if (productPromotionsCache[key]) return productPromotionsCache[key]
        try {
            const {data: result} = await httpPostGraphQL({
                query: productsOutdoor,
                variables: {
                    name, pageSize
                }
            });

            if (result.errors) return Promise.reject(result.errors);

            const {data: {productSearch: {result: items, total}}} = result;

            productPromotionsCache[key] = {
                items,
                total
            }

            return Promise.resolve({
                items,
                total
            })
        } catch (e) {
            console.error(`Falha na consulta ${name}, pageSize: ${pageSize}`, e);
            return Promise.reject(e);
        }
    }, []);

    const getProductDetails = useCallback(async (_id: string): Promise<ITkProductModel | undefined | null> => {

        const hydratedProductDetail = document.getElementById('__productDetail')
        // @ts-ignore caso o detalhe do produto já tenha sido injetado, não precisa buscar
        if (hydratedProductDetail) {
            const detail = JSON.parse(hydratedProductDetail.innerText)
            hydratedProductDetail.remove()
            return detail
        }
        try {
            const {data: result} = await httpPostGraphQL({
                query: productDetailQuery,
                variables: {_id}
            });

            if (result.errors) return Promise.reject(result.errors);

            const {data: {productDetail}} = result;
            return Promise.resolve(productDetail);

        } catch (e) {
            console.error('Falha na consulta de detalhe do produto', e);
            return Promise.reject(e);
        }
    }, []);

    const getRelatedProducts = useCallback(async (mainName: string, divisionId: string, pageSize?: number): Promise<ProductListResult | undefined | null> => {
        const key = `${mainName}-${divisionId}-${pageSize || 10}`
        if (productPromotionsCache[key]) return productPromotionsCache[key]

        try {
            const {data: result} = await httpPostGraphQL({
                query: relatedProducts,
                variables: {
                    mainName, divisionId, pageSize
                }
            });

            if (result.errors) return Promise.reject(result.errors);

            const {data: {productSearch: {result: items, total}}} = result;

            productPromotionsCache[key] = {
                items,
                total
            }

            return Promise.resolve({
                items,
                total
            })
        } catch (e) {
            console.error('Falha na consulta de produtos relacionados', e);
            return Promise.reject(e);
        }

    }, []);

    const autoComplete = useCallback(async (text: string, divisionId?: string): Promise<AutoCompleteResult | undefined | null> => {
        try {
            const {data: result} = await httpPostGraphQL({
                query: autoCompleteQuery,
                variables: {text, divisionId}
            });

            if (result.errors) return Promise.reject(result.errors);

            const {data: {searchAutoComplete: {suggestions, total}}} = result;
            return Promise.resolve({
                suggestions,
                total
            });
        } catch (e) {
            console.error('Falha ao fazer auto complete', e);
            return Promise.reject(e);
        }
    }, []);

    const productSearch = useCallback(async ({
                                                 term: name, divisionId, familiesIds,
                                                 classesIds, groupsIds, manufacturersIds,
                                                 from, pageSize, priceStart,
                                                 priceEnd, nameOrder, priceOrder, stars,
                                                 showAvailableStock, showPromotions
                                             }): Promise<ProductListResult | undefined | null> => {


        if (!isBlank(priceStart)) priceStart = parseFloat(priceStart);
        if (!isBlank(priceEnd)) priceEnd = parseFloat(priceEnd);

        const variables = {
            name, divisionId,
            familiesIds, classesIds,
            groupsIds, manufacturersIds,
            from, pageSize,
            priceStart, priceEnd,
            nameOrder, priceOrder, stars,
            showAvailableStock, showPromotions
        }

        if (isSomething(stars)) variables.stars = stars instanceof Array ? stars : [stars];
        else delete variables.stars;

        if (isSomething(manufacturersIds)) variables.manufacturersIds = manufacturersIds instanceof Array ? manufacturersIds : [manufacturersIds];
        else delete variables.manufacturersIds;

        if (isSomething(familiesIds)) variables.familiesIds = familiesIds instanceof Array ? familiesIds : [familiesIds];
        else delete variables.familiesIds;

        if (isSomething(classesIds)) variables.classesIds = classesIds instanceof Array ? classesIds : [classesIds];
        else delete variables.classesIds;

        try {
            const {data: result} = await httpPostGraphQL({
                query: productSearchQuery(nameOrder, priceOrder),
                variables
            });

            if (result.errors) return Promise.reject(result.errors);

            const {data: {productSearch: {result: items, total}}} = result;
            return Promise.resolve({
                items,
                total
            });
        } catch (e) {
            console.error('Falha ao fazer auto complete', e);
            return Promise.reject(e);
        }
    }, []);

    const loadClassifiers = useCallback(async (...classifiers: ClassifiersType[]) : Promise<ClassifiersResult> => {
        try {

            if (state.isLoadingClassifiers) return;

            const classifiersResult: ClassifiersResult = {}

            for (const c of classifiers) {
                if (state?.[c]?.length > 0) classifiersResult[c] = state[c]
            }

            const inCacheKeys = Object.keys(classifiersResult)
            if (classifiers.length === inCacheKeys.length) return classifiersResult

            const classifiersNotLoaded = classifiers.filter(c => !inCacheKeys.includes(c))

            dispatch({
                type: ProductReducerActionType.set_loading_classifiers,
                payload: true
            })

            const {data: result} = await httpPostGraphQL({
                query: classifiersQuery(...classifiersNotLoaded)
            });

            if (result.errors) return Promise.reject(result.errors);

            for (const c of classifiersNotLoaded) {

                const payload: any = result.data[`all${capitalize(c)}`]

                if (c === ClassifiersType.DIVISIONS) {
                    payload.forEach((p: any) => p.icon = bindDivisionIcon[p.name])
                }

                dispatch({
                    type: c as any,
                    payload
                })

                classifiersResult[c] = result.data[c]
            }

            return classifiersResult
        } catch (e) {
            console.error('Falha ao fazer carregar classificadores', e);
            throw e
        } finally {
            dispatch({
                type: ProductReducerActionType.set_loading_classifiers,
                payload: false
            })
        }
    }, [])

    const getBestClasses = useCallback(async (): Promise<TTkBestClassesResult[] | undefined | null> => {
        try {

            if (bestClassesCache.length > 0) return Promise.resolve(bestClassesCache);

            const {data: result} = await httpPostGraphQL({
                query: bestClassesQuery
            });

            if (result.errors) return Promise.reject(result.errors);

            const {data: {bestClasses}} = result;
            bestClassesCache = bestClasses;
            return Promise.resolve(bestClasses);
        } catch (e) {
            console.error('Falha ao recuperar as melhores classes de produtos', e);
            return Promise.reject(e);
        }
    }, []);

    const getBestProductsManufacturers = useCallback(async (..._ids: string[]): Promise<TTkBestManufacturersResult[] | undefined | null> => {
        try {

            if (bestProductsManufacturersCache.length > 0) return Promise.resolve(bestProductsManufacturersCache);

            const {data: result} = await httpPostGraphQL({
                query: bestProductsManufacturersQuery,
                variables: {_ids}
            });

            if (result.errors) return Promise.reject(result.errors);

            const {data: {bestProductsManufacturers}} = result;
            bestProductsManufacturersCache = bestProductsManufacturers;
            return Promise.resolve(bestProductsManufacturers);
        } catch (e) {
            console.error('Falha ao recuperar as melhores fabricantes', e);
            return Promise.reject(e);
        }
    }, []);

    const getBestProductsCategories = useCallback(async (divisionsIds: string[], familiesIds: string[], classesIds: string[]): Promise<TTkBestProductsCategoriesResult[] | undefined | null> => {
        try {

            if (bestProductsCategoriesCache.length > 0) return Promise.resolve(bestProductsCategoriesCache);

            const {data: result} = await httpPostGraphQL({
                query: bestProductsCategoriesQuery,
                variables: {divisionsIds, familiesIds, classesIds}
            });

            if (result.errors) return Promise.reject(result.errors);

            const {data: {bestProductsCategories}} = result;
            bestProductsCategoriesCache = bestProductsCategories;
            return Promise.resolve(bestProductsCategories);
        } catch (e) {
            console.error('Falha ao recuperar as melhores categorias de produtos', e);
            return Promise.reject(e);
        }
    }, []);

    const getSimilarityProducts = useCallback(async (externalId: string): Promise<ITkProductModel[] | undefined | null> => {
        try {
            if (similarityProductsCache[externalId]) return similarityProductsCache[externalId]

            const {data: result} = await httpPostGraphQL({
                query: similarityProductsQuery,
                variables: {externalId}
            });

            if (result.errors) return Promise.reject(result.errors);

            const {data: {searchSimilarityExternalId}} = result;
            similarityProductsCache[externalId] = searchSimilarityExternalId
            return Promise.resolve(searchSimilarityExternalId);
        } catch (e) {
            console.error('Falha ao recuperar os produtos similares', e);
            return Promise.reject(e);
        }
    }, []);

    useEffect(() => {
        if (!state.divisions || state.divisions.length === 0) {
            loadClassifiers(ClassifiersType.DIVISIONS)
                .catch(e => {
                    const msg = 'Falha ao carregar divisões';
                    console.error(msg, e);
                    sendException(msg, false)
                })

        }
    }, [state.divisions])

    return {
        getProductsOutdoor,
        getRelatedProducts,
        getProductDetails,
        productSearch,
        loadClassifiers,
        autoComplete,
        state,
        getBestClasses,
        getBestProductsManufacturers,
        getBestProductsCategories,
        getSimilarityProducts,
    }
};

export default TkProductContext
