import React, {FC, useCallback, useState} from 'react';
import {ITkProductQuantityModel} from "../../models/product";
import {useTkDeakContext} from "../../context/TkContext";
import TkLazyImage from "../../components/particles/TkLazyImage";
import NoImage from "../../assets/images/no-photo-01.svg";
import {formatMoney} from "../../utils/number-utils";
import {TkProductListDeakPriceType} from "../../models/list";
import TkSkeleton from "../../components/particles/TkSkeleton";
import {onlyNumbers} from "../../utils/string-utils";

const CardProduct:FC<{
    item: ITkProductQuantityModel
    price: number
    changePrice: (productId: string, newPrice: number) => void
}> = ({item, price, changePrice}) => {
    const formatCurrency = (value: number) => {
        return (value / 100).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    return <article className='TkMakeDeakOrderView__product-card'>
        <TkLazyImage src={item.product.thumbnail ?? NoImage}
                     className='img-responsive'
                     alt={item.product.name}/>
        <header>
            <h1>{item.product.name}</h1>
        </header>
        <div className='TkMakeDeakOrderView__product-card-price'>
            <span>
                Valor sistema<br/>
                {formatMoney(item.price)}
            </span>
            <span className='t-a-r'>
                Valor negociado<br/>
                <input type="text"
                       value={formatCurrency(price)}
                       onChange={(e) => changePrice(item.product._id, parseFloat(onlyNumbers(e.target.value)))}
                       className='form-control form-control-sm TkMakeDeakOrderView__input t-a-r'
                       placeholder='Informe o valor'/>
            </span>
        </div>
    </article>
}

const _stepChangePrices: React.FC<{
    next: () => void;
    back: () => void;
    isInOrOut: boolean;
}> = ({next, back, isInOrOut = true}) => {
    const {list, isLoadingList} = useTkDeakContext()
    const {setPrices: savePrices} = useTkDeakContext()
    const [prices, setPrices] = useState<TkProductListDeakPriceType>(null)

    function changePrice(productId: string, newPrice: number) {
        const newValue = {
            [productId]: newPrice
        }

        setPrices((prev) => {
            if (!prev) return newValue
            prev[productId] = newPrice
            return {...prev}
        })
    }

    const price = useCallback((item: ITkProductQuantityModel) => {
        if (prices && prices[item.product._id] >= 0) return prices[item.product._id]
        return item.price*100
    }, [prices])

    const confirmData = (e: React.FormEvent) => {
        e.preventDefault();

        const pricesToSave = Object.keys(prices || {})
            .filter((productId) => prices[productId] > 0)
            .reduce((obj, productId) => {
                obj[productId] = prices[productId]
                return obj
            }, {} as TkProductListDeakPriceType)

        if (Object.keys(pricesToSave).length > 0) savePrices(pricesToSave)
        next()
    }

    const productList = () => {
        return <form onSubmit={confirmData} className='TkMakeDeakOrderView__form'>
            {isLoadingList ?
                Array.from({length: 3}).map((_, idx) => (
                    <TkSkeleton key={idx}
                                style={{
                                    width: '100%',
                                    height: 130,
                                    borderRadius: 4
                                }}/>
                ))
                :
                list?.items?.map((item, idx) => (
                    <CardProduct item={item} key={idx}
                                 price={price(item)}
                                 changePrice={changePrice}/>
                ))
            }

            <div className='TkMakeDeakOrderView__button-container'>
                <button className='btn btn-secondary'
                        onClick={back}
                        type='button'>Voltar
                </button>
                <button className='btn btn-primary'
                        type='submit'>Salvar e finalizar
                </button>
            </div>
        </form>
    }

    return productList();
};


export default _stepChangePrices;
