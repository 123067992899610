import React from "react";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkSlider from "../../components/particles/TkSlider";
import TkCarouselBestManufacturers from "../../components/product/./TkCarouselBestManufacturers";
import TkCarouselBestCategories from "../../components/product/./TkCarouselBestCategories";
import TkCarouselBestClasses from "../../components/product/./TkCarouselBestClasses";
import TkCarouselBestProducts from "../../components/product/./TkCarouselBestProducts";
import TkCarouselOtherProducts from "../../components/product/TkCarouselOtherProducts";
import {getS3Object} from "../../utils/http-utils";


const MediumLargeScreen: React.FC = () => {
    const slides = [{
        img: getS3Object("/plenobras/banners/capa-eletrica.png"),
        text: "",
        style: {
            color: "#ffffff",
            fontSize: "14px",
            marginTop: "3rem",
        },
    }, {
        img: getS3Object("/plenobras/banners/capa-hidraulica.png"),
        text: "",
        style: {
            color: "#000000",
            fontSize: "14px",
            marginTop: "3rem",
        },
    }];

    return <div className="TkWorldContainer">
        <TkDivisionsTop/>
        <TkSlider slides={slides} autoPlay={true}/>

        <TkCarouselBestProducts/>

        <TkCarouselBestManufacturers/>

        <TkCarouselBestCategories/>

        <TkCarouselBestClasses/>

        <TkCarouselOtherProducts/>
    </div>
};
export default MediumLargeScreen;
