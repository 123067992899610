import {Helmet} from "react-helmet";
import {User} from "../models/user";
import {isBlank} from "./string-utils";
import {ITkProductQuantityModel} from "../models/product";
import {ITkCart} from "../models/cart";
// @ts-ignore
const isLoaded = () => !!window.gtag;


function getPageTitle() {
    const helmet = Helmet.peek() || {};

    // @ts-ignore
    return helmet.title || 'Plenobras';
}

/**
 * Envia pageview para o google. Timeout para garantir que os dados chamados via XHR sejam carregados
 * @param path
 */
export const pageView = (path: string) => {
    if (isLoaded()) {
        const hit = () => {
            // @ts-ignore
            window.gtag('config', process.env.REACT_APP_ANALYTICS_TRACK_GA4, {
                'page_title': getPageTitle(),
                'page_path': path
            });
            // @ts-ignore
            window.gtag('config', process.env.REACT_APP_G_ADS, {
                'page_title': getPageTitle(),
                'page_path': path
            });
            // @ts-ignore
            window.fbq('track','PageView', {
                'page_title': getPageTitle(),
                'page_path': path
            });
        }
        if (window.requestIdleCallback) {
            window.requestIdleCallback(() => setTimeout(hit, 300));
        } else {
            setTimeout(hit, 300)
        }
    }
}


/**
 * Envia eventos para o Analytics.
 * @param name
 * @param category
 * @param label
 * @param value
 * @returns {boolean}
 */
export const sendAnalyticsEvent = (name: string, category: string, label: string, value?: any) => {
    if (!isLoaded()) return false;

    const event: any = {
        'event_category': category,
        'event_label': label
    }

    if (!isBlank(value)) event.event_value = value;

    // @ts-ignore
    window.gtag('event', name, event);
}

/**
 * Registar evento de pesquisa por termo
 *
 * @param term
 */
export const sendSearchTerm = (term: string) => {
    if (!isLoaded()) return false;

    // @ts-ignore
    window.gtag('event', 'search', term);
}


/**
 * Envia interação de redes sociais.
 * @param socialNetwork
 * @param socialAction
 * @param url
 * @returns {boolean}
 */
export const sendSocialInteraction = (socialNetwork: string, socialAction = 'like', url: string) => {
    if (!isLoaded()) return false;
    // @ts-ignore
    window.gtag('event', 'socialInteraction', {
        'socialNetwork': socialNetwork,
        'socialAction': socialAction,
        'socialTarget': url
    });
}

/**
 * Envia exceções para o Google Analytics
 * @param description
 * @param isFatal
 * @returns {boolean}
 */
export const sendException = (description = 'Falha na execução', isFatal = false) => {
    if (!isLoaded()) return false;

    // @ts-ignore
    window.gtag('event', 'exception', {
        'description': description,
        'fatal': isFatal
    });
}

const cartToItems = (cart: ITkCart) => {
    return cart.items.reduce((obj: any, val: ITkProductQuantityModel) => {
        const item: any = {
            id: val.product._id,
            name: val.product.name,
            list_name: 'Search Results',
            list_position: obj.length + 1,
            price: val.price,
            quantity: val.quantity
        }

        if (val.product.manufacturerName)
            item.brand = val.product.manufacturerName;

        if (val.product?.hierarchies?.length > 0)
            item.category = val.product.hierarchies[0].family.name;

        obj.push(item);

        return obj;
    }, [])
}

/**
 * Adiciona ou remove do carrinho de compras
 *
 * @param cart
 * @param isAdd
 */
export const addOrRemoveToCart = (cart: ITkCart, isAdd = true) => {
    if (!isLoaded()) return false;

    try {
        // @ts-ignore
        window.gtag('event', isAdd ? 'add_to_cart' : 'remove_from_cart', {
            'value': cart.totalValue,
            'currency': 'BRL',
            'items': cartToItems(cart)
        });
        // @ts-ignore
        window.fbq('track', isAdd ? 'AddToCart' : 'RemoveFromCart', {
            'value': cart.totalValue,
            'currency': 'BRL',
            'items': cartToItems(cart)
        });
    } catch (e) {
        console.error('Falha ao adionar item no carrinho Analytics', e);
    }
}

/**
 * Incia o processo de Checkout
 *
 * @param cart
 */
export const beginCheckout = (cart: ITkCart) => {
    if (!isLoaded()) return false;

    try {
        // @ts-ignore
        window.gtag('event', 'begin_checkout', {
            'value': cart.totalValue,
            'currency': 'BRL',
            'items': cartToItems(cart)
        });
        // @ts-ignore
        window.fbq('track', 'InitiateCheckout', {
            'value': cart.totalValue,
            'currency': 'BRL',
            'items': cartToItems(cart)
        });
    } catch (e) {
        console.error('Falha ao iniciar checkout Analytics', e);
    }
}

/**
 * Anda no processo de checkout
 *
 * @param cart
 * @param step
 */
export const checkoutProgress = (cart: ITkCart, step: number) => {
    if (!isLoaded()) return false;

    try {
        // @ts-ignore
        window.gtag('event', 'checkout_progress', {
            'value': cart.totalValue,
            'currency': 'BRL',
            'checkout_step': step,
            'items': cartToItems(cart)
        });
    } catch (e) {
        console.error('Falha ao progredir no checkout Analytics', e);
    }
}

/**
 * Finaliza a compra
 *
 * @param cart
 * @param transactionId
 */
export const purchase = (cart: ITkCart, transactionId: string) => {
    if (!isLoaded()) return false;

    try {
        // @ts-ignore
        window.gtag('event', 'purchase', {
            value: cart.totalValue,
            currency: 'BRL',
            transaction_id: transactionId,
            affiliation: 'Plenobras Intermediação de Negócios em Suprimentos Empresariais LTDA',
            tax: 0.0,
            shipping: 0.0,
            items: cartToItems(cart)
        });
        // @ts-ignore
        window.gtag('track', 'Purchase', {
            value: cart.totalValue,
            currency: 'BRL',
            transaction_id: transactionId,
            affiliation: 'Plenobras Intermediação de Negócios em Suprimentos Empresariais LTDA',
            tax: 0.0,
            shipping: 0.0,
            items: cartToItems(cart)
        });
    } catch (e) {
        console.error('Falha ao finalizar compra Analytics', e);
    }
}

/**
 * Configura a Sessão do usuário no Analytics.
 *
 * @param user
 * @returns {boolean}
 */
export const setAnalyticsUser = (user: User) => {
    if (!isLoaded()) return false;
    // @ts-ignore
    window.gtag('config', process.env.REACT_APP_ANALYTICS_TRACK_GA4, {
        'user_id': `${user._id}`
    });
}
