import React from "react";
import {Link, useLocation} from 'react-router-dom';
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {tk_route_root} from "../TkHomeView";
import {tk_route_product_detail} from "../TkProductDetailView";

export const tk_route_404 = '*';
export const tk_route_product_not_found_view = '/produto-nao-encontrado';

const Tk404View: React.FC = () => {

    const location = useLocation();
    // @ts-ignore
    const productNotFound = location.state?.productNotFound;



    function getTitle(isSuffix: boolean = true) {
        const title = []
        if (productNotFound) title.push(`Produto não encontrado: ${productNotFound._id}`);
        else title.push('Página não encontrada');

        if (isSuffix) title.push(' | Plenobras');

        return title.join('');
    }


    return <>
        <TkHeadSEO title={getTitle()}/>

        <TkHeader/>

        <TkDivisionsTop/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_404,
                    label: getTitle(false)
                }
            ]}/>

            {productNotFound ? <>
                <h1>Produto não encontrado</h1>

                <p>Não encontramos o produto {tk_route_product_detail({
                    _id: productNotFound._id,
                    name: productNotFound.name
                })}</p>
            </> : <>
                <h1>Página não encontrada</h1>

                <p>Não encontramos a página que você tentou acessar <code>{location.pathname}</code>.</p>
            </>}

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <p><Link to={tk_route_root} title='Voltar a tela inicial do site'>Volte para o início do site</Link>.</p>
        </div>

        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default Tk404View
