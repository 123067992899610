import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import './assets/stylesheets/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {isIntersectionObserver, isProdEnv} from "./utils/utils";
import dayjs from "dayjs";
import customParseForm from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseForm);


if (!isIntersectionObserver()) {
    const intersect = document.createElement('script');
    intersect.setAttribute('src', `/assets/javascript/intersection-observer.min.js`);
    document.head.appendChild(intersect);
}

if (isProdEnv()) {
    const cloudFront = document.createElement('script');
    cloudFront.setAttribute('src', 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/da53ebe4-035f-4ee0-8ce2-547ae1e03f24-loader.js');
    document.head.appendChild(cloudFront);
}

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
serviceWorker.register();
