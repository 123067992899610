import React, {useCallback, useEffect, useState} from "react";
import './style.scss'
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkProportionalImage from "../../components/particles/TkProportionalImage";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {Link, useParams} from "react-router-dom";
import {ITkProductModel} from "../../models/product";
import {useTkCart, useTkMedia} from "../../context/TkContext";
import {formatMoney} from "../../utils/number-utils";
import {tk_route_my_cart} from "../TkMyCartView";
import {tk_route_checkout} from "../TkCheckoutView";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import TkComplementCart from "../../components/cart/TkComplementCart";

const NoPhoto = 'https://teky.s3.sa-east-1.amazonaws.com/no-photo.svg';

export const tk_route_item_added_to_cart = (product?: ITkProductModel) => {
  const route = '/carrinho-de-compras/:id/item-adicionado';
  if (product) return route.replace(/:id/gi, `${product._id}`);
  return route;
};

const InfoCard: React.FC = () => {
  const {id} = useParams();
  const {currentCart} = useTkCart();
  const [_, setHeight] = useState(window.innerWidth);

  const getItemAdded = useCallback(() => {
    if (currentCart && currentCart.items) {
      // @ts-ignore
      const item = currentCart.items.filter(item => item?.product?._id === id);
      if (item && item.length > 0) return item[0].product;
    }

    return null;
  }, [id, currentCart]);

  let totalQuantity = currentCart && currentCart.items ? currentCart.items.length : 0;
  let totalValue = currentCart && currentCart.totalValue ? currentCart.totalValue : 0;

  const itemAdded = getItemAdded();

  return <div className="container m-t-1rem">
    <div className='TkItemAddedToCart__card'>
      <div className='TkItemAddedToCart__item'>
        <TkSvgIcon icon='check-solid'/>
        <TkProportionalImage key={id} url={itemAdded?.thumbnail || NoPhoto} plainB={NoPhoto}
                             alt={itemAdded?.name ?? "Thumbnail"} listenHeight={setHeight}/>
        <h1>Adicionado <br/>ao<br/> carrinho</h1>
      </div>
      <div className='TkItemAddedToCart__subtotal'>
        <h2><b>Subtotal do carrinho</b> ({totalQuantity} {totalQuantity > 1 ? 'itens' : 'item'}): <span
          className='price'>{formatMoney(totalValue)}</span></h2>
      </div>
      <div className='TkItemAddedToCart__actions'>
        <Link to={tk_route_my_cart} className='button'>
          <TkSvgIcon icon='shopping-cart-solid'/>
          Carrinho
        </Link>
        <Link to={tk_route_checkout} className='button-2'>
          <TkSvgIcon icon='check-solid'/>
          Fechar Pedido
        </Link>
      </div>
    </div>
  </div>;
};

const TkItemAddedToCartView: React.FC = () => {
  const params = useParams<any>();
  const [relatedProducts, setRelatedProducts] = useState<ITkProductModel[]>();
  const {currentCart} = useTkCart();
  const {isMediaXs, isMediaSm} = useTkMedia();

  const getItemAdded = useCallback(() => {
    if (currentCart && currentCart.items) {
      // @ts-ignore
      const item = currentCart.items.filter(item => item?.product?._id === params.id);
      if (item && item.length > 0) return item[0].product;
    }

    return null;
  }, [params, currentCart]);

  useEffect(() => {
    if (!!relatedProducts) {
      window.scrollTo(0, 0);
      return;
    }
    const itemAdded = getItemAdded();
    if (!!itemAdded) window.scrollTo(0, 0);
  }, [currentCart]);

  return <div className='TkItemAddedToCart'>
    <TkHeadSEO title='Item Adicionado ao Carrinho | Plenobras'/>

    <TkHeader/>

    <TkDivisionsTop/>

    <div className="container m-t-1rem">
      <TkBreadcrumb list={[
        {
          url: tk_route_item_added_to_cart({
            _id: params.id
          }),
          label: 'Item Adicionado ao Carrinho'
        }
      ]}/>
    </div>

    <InfoCard/>

    <TkComplementCart/>

    <TkNewsLetter/>
    <TkFooter/>
  </div>
};


export default TkItemAddedToCartView
