import React, {FC, memo, useContext, useEffect, useRef, useState} from "react";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {useTkBottomSheetContext, useTkUser} from "../../context/TkContext";
import {Link, useHistory, useLocation} from "react-router-dom";
import {formatMoney} from "../../utils/number-utils";
import {humanBadgeCount, isBlank} from "../../utils/string-utils";
import TkModal, {TkModalActions, TkModalBody} from "../../components/particles/TkModal";
import {toast} from "react-toastify";
import {useListDownload} from "../../hooks/useListDownload";
import {FileTypes} from "../../models/file";
import {tk_route_my_list} from "../TkMyListsView";
import TkSkeleton from "../../components/particles/TkSkeleton";
import _ListContext from "./_listContext";
import TkModalEditList, {ITkModalEditList} from "../../components/list/TkModalEditList";
import {tk_route_deak_order} from "../TkMakeDeakOrderView";

const _ListInfo: FC<{
    className?: string
    mode?: 'bottom-sheet' | 'full-screen'
}> = ({className, mode = 'full-screen'}) => {
    const {sendList} = useTkUser()
    const {list, isLoadingList, loadList, setList} = useContext(_ListContext)
    const {setShow, isShow} = useTkBottomSheetContext()
    const {search, pathname, state} = useLocation()
    const history = useHistory()
    const params = new URLSearchParams(search)
    const listId = params.get('listId')
    const {download: downloadXlsx} = useListDownload(list, FileTypes.XLSX)
    const {download: downloadPdf} = useListDownload(list, FileTypes.PDF)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [email, setEmail] = useState('')
    const [isSending, setSending] = useState(false)
    const [isDownloadExcel, setDownloadExcel] = useState(false)
    const [isDownloadPdf, setDownloadPdf] = useState(false)
    const modalListManagerRef = useRef<ITkModalEditList>();

    useEffect(() => {
        if (!isBlank(listId)) loadList(listId)
    }, [listId]);


    function goToProduct(externalId: string) {
        params.set('term', `teky:${externalId}`)
        params.delete('from')
        history.push(`${pathname}?${params.toString()}`)
    }

    if (isBlank(listId)) return <p className='t-a-c m-t-40px'>Selecione uma lista ou crie uma nova</p>

    if (isLoadingList) return <TkSkeleton style={{
        marginTop: 10,
        height: 400,
    }}/>

    return <>

        <div
            className={`TkSellerListManagerView__list ${mode === 'bottom-sheet' ? 'TkSellerListManagerView__list-bottom-sheet-mode' : ''} ${className}`}>

            {mode === 'bottom-sheet' && !isShow ? <div className='d-flex a-i-c j-c-sb m-b-10px'>
                    <span className="subtotal ellipsis">Subtotal: {formatMoney(list?.totalValue)}</span>
                    <button type="button"
                            className='button-7 p-l-10px p-r-10px'
                            onClick={() => setShow(true)}>Ver Lista</button>
                </div>
                :
                <>
                    <h1 className='ellipsis' title={list?.name}>{list?.name}</h1>
                    <span className='customer ellipsis'>{list?.buildingName}</span>
                    <span className="subtotal ellipsis">Subtotal: {formatMoney(list?.totalValue)}</span>
                </>
            }

            <Link to={tk_route_deak_order(list?._id)}
                  className='button-2 d-flex-i a-i-c m-t-10px w-100'>
                <TkSvgIcon
                    style={{margin: 0, marginRight: 10, marginLeft: 5}}
                    icon={'check-solid'}
                />Fazer pedido
            </Link>

            <button type='button'
                    onClick={() => {
                        setEmail('')
                        setShowConfirmation(true)
                    }}
                    className='button-5 d-flex-i a-i-c m-t-10px w-100'>
                <TkSvgIcon
                    style={{margin: 0, marginRight: 10}}
                    icon={'envelope-regular'}
                />Enviar por e-mail
            </button>
            <div className='d-flex a-i-c j-c-sb w-100'>
                <Link to={tk_route_my_list(listId)} className='c-p-c' title='Ver detalhes da lista'>Visualizar lista</Link>
                <Link to={{
                    pathname,
                    search,
                    state,
                }} replace onClick={() => modalListManagerRef.current.show(list)} className='c-p-c' title='Editar lista'>Editar lista</Link>
            </div>

            <div className='TkSellerListManagerView__download-options m-t-10px'>
                <span className='d-flex-i a-i-c' onClick={() => {
                    if (!isLoadingList) {
                        setDownloadExcel(true)
                        downloadXlsx()
                            .finally(() => setDownloadExcel(false))
                    }
                }}>
                    <TkSvgIcon icon={isDownloadExcel ? 'sync-solid' : 'file-excel-solid'}
                               className={isDownloadExcel ? 'rotate-1-seg' : ''}/>
                    Excel
                </span>
                <span className='d-flex-i a-i-c' onClick={() => {
                    if (!isLoadingList) {
                        setDownloadPdf(true)
                        downloadPdf()
                            .finally(() => setDownloadPdf(false))
                    }
                }}>
                    <TkSvgIcon icon={isDownloadPdf ? 'sync-solid' : 'file-pdf-solid'}
                               className={isDownloadPdf ? 'rotate-1-seg' : ''}/>
                    PDF
                </span>

            </div>

            <ul className={`TkSellerListManagerView__list-products ${mode === 'full-screen' ? 'm-t-10px' : ''}`}>
                {list?.items?.map((item) => (
                    <li key={item.product._id} onClick={() => goToProduct(item.product._id)}>
                        <h1 className='ellipsis' title={item.product.name}>{item.product.name}</h1>
                        <small title={item.quantity + ''}>{humanBadgeCount(item.quantity)}</small>
                        <div className='d-flex a-i-c w-100'>
                            <span>Valor<br/>{formatMoney(item.price)}</span>
                            <span>Total<br/>{formatMoney(item.total)}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
        <TkModal show={showConfirmation} title='Confirmação' close={() => setShowConfirmation(false)}>
            <TkModalBody>
                <p>Informe o e-mail que deseja enviar a lista</p>
                <input type='email' className='form-control'
                       value={email}
                       placeholder='Informe o e-mail'
                       onChange={(e) => setEmail(e.target.value)}/>
            </TkModalBody>

            <TkModalActions>
                <button type='button'
                        disabled={isSending}
                        className='btn btn-secondary'
                        onClick={() => setShowConfirmation(false)}>Fechar
                </button>
                <button type='button'
                        disabled={isSending}
                        className='btn btn-primary'
                        onClick={async () => {
                            try {
                                setSending(true)
                                await sendList(listId, email)

                                setShowConfirmation(false)
                                toast.success('Lista enviada com sucesso')
                            } catch (e) {
                                console.error(e)
                                toast.success('Ops... Algo deu errado, tente novamente.')
                            } finally {
                                setSending(false)
                            }

                        }}>{isSending ? 'Enviando...' : 'Enviar'}</button>
            </TkModalActions>
        </TkModal>

        <TkModalEditList updateListState={(listUpdated) => {
            modalListManagerRef.current.close()
            setList(listUpdated)
            toast.info('Lista alterada com sucesso')
        }} ref={modalListManagerRef}/>
    </>
}

export default memo(_ListInfo)