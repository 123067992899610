import React, {FC, memo, useCallback, useEffect, useRef, useState} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import {TkLogoBuilder} from "../../components/particles/TkLogoVector";
import {useTkMedia, useTkUser} from "../../context/TkContext";
import TkDivider from "../../components/particles/TkDivider";
import {tk_var_teky_address1, tk_var_teky_document, tk_var_teky_name} from "../TkBeInTouchView";
import {formatDocument, isBlank, isCnpj, isCpf, isEmail} from "../../utils/string-utils";
import {useHistory, useLocation} from "react-router-dom";
import {AccountType} from "../../models/user";
import {getIcon, getTitle} from "./_utils";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import Cleave from "cleave.js";
import {animated as a, useTrail} from "react-spring";
import {isArray, vibrate} from "../../utils/utils";

const FormBusiness:FC = () => {
    const history = useHistory()
    const {search, pathname} = useLocation()
    const {isMediaXs, isMediaSm} = useTkMedia()
    const {saveCompany} = useTkUser()
    const [taxContributingType, setTaxContributingType] = useState('contribute')
    const {value: document, bind: bindDocument, setValue: setDocument} = useSimpleInput('');
    const {value: socialReason, bind: bindSocialReason} = useSimpleInput('');
    const {value: taxContributingCode, bind: bindTaxContributingCode} = useSimpleInput('');
    const {value: emailInvoice, bind: bindEmailInvoice} = useSimpleInput('');
    const docRef = useRef(null);
    const [errors, setErrors] = useState([]);
    const [isUpdating, setUpdating] = useState(false);

    const config = {mass: 5, tension: 2000, friction: 200}
    const trail = useTrail(errors.length, {
        config,
        opacity: errors.length > 0 ? 1 : 0,
        from: {opacity: 0},
    });

    useEffect(() => {
        let docCleave: Cleave | null;

        if (docRef.current) {
            docCleave = new Cleave(docRef.current, {
                numericOnly: true,
                blocks: [2, 3, 3, 4, 2],
                delimiters: [".", ".", "/", "-"],
                onValueChanged: ({target}) => {
                    target.value.replace(/\D+/gi, '');
                    setDocument(target.value)
                }
            });
        }

        return () => {
            if (docCleave) docCleave.destroy();
            docCleave = null;
        }
    }, [docRef, setDocument])


    const onSubmit = useCallback(async (e: any) => {
        e.preventDefault();

        const newState = [];

        if (isBlank(document)) newState.push('Preencha o CNPJ');
        else if (!isCnpj(document)) newState.push('CNPJ Inválido');
        if (isBlank(socialReason)) newState.push('Preencha a Razão Social');
        if (isBlank(taxContributingType)) newState.push('Preencha o Tipo de Contribuinte');
        if (isBlank(emailInvoice)) newState.push('Preencha o E-mail para envio de nota');
        else if (!isEmail(emailInvoice)) newState.push('E-mail inválido');

        setErrors(newState)

        if (newState.length === 0) {
            setUpdating(true)

            try {

                await saveCompany({
                    document: docRef.current.value,
                    socialReason,
                    taxContributingType: taxContributingType,
                    taxContributingCode,
                    emailInvoice,
                    isDefault: true
                });

                const p = new URLSearchParams(search)
                p.set('estagio', 'criacao-sucesso')

                history.replace(`${pathname}?${p.toString()}`)

            } catch (e) {
                if (isArray(e)) {
                    e.forEach((obj: any) => newState.push(obj.message));
                    setErrors(newState);
                }
                vibrate()
            } finally {
                setUpdating(false)
            }
        }

    }, [document, socialReason, taxContributingCode, taxContributingType, emailInvoice, setUpdating, setErrors])

    return <form style={{
        minWidth: isMediaXs || isMediaSm ? '100%' : 700
    }} onSubmit={onSubmit} noValidate>
        <div className='row'>
            <div className='col-md-6 t-a-l'>
                <label htmlFor="signUpCompleteCnpj" className='m-t-10px'>CNPJ Empresa <span style={{
                    color: 'red'
                }}>*</span></label>
                <input type="text"
                       id='signUpCompleteCnpj'
                       ref={docRef}
                       placeholder='##.###.###/####-##'
                       required
                       className='form-control form-control-sm' {...bindDocument}/>
            </div>
            <div className='col-md-6 t-a-l'>
                <label htmlFor="signUpCompleteNickName" className='m-t-10px'>Razão Social <span style={{
                    color: 'red'
                }}>*</span></label>
                <input type="text"
                       id='signUpCompleteNickName'
                       placeholder='Informe a razão social de sua empresa'
                       required
                       className='form-control form-control-sm' {...bindSocialReason}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-6 t-a-l'>
                <label htmlFor="signUpCompleteType" className='m-t-10px'>Tipo Contribuinte <span style={{
                    color: 'red'
                }}>*</span></label>
                <select id='companyEditTaxContributeType'
                        value={taxContributingType}
                        onChange={e => setTaxContributingType(e.target.value)}
                        className='form-control form-control-sm'>
                    <option title="Pessoa Jurídica com Inscrição estadual que recolhe ICMS" value="contribute">Contribuinte</option>
                    <option title="Pessoa Jurídica (sem IE) que não recolher ICMS" value="not_contribute">Não Contribuinte</option>
                    <option title="Pessoa Jurídica com IE que não recolhe ICMS, Ex.: Construtoras" value="not_contribute_with_ie">Não Contribuinte com IE</option>
                    <option title="Órgão Público" value="public_institution">Órgão Público</option>
                </select>
            </div>
            <div className='col-md-6 t-a-l'>
                <label htmlFor="signUpCompleteStateInscription" className='m-t-10px'>Inscrição Estadual <small>(se aplicável)</small></label>
                <input type="text"
                       id='signUpCompleteStateInscription'
                       className='form-control form-control-sm' {...bindTaxContributingCode}/>
            </div>
        </div>

        <div className="row">
            <div className='col-12 t-a-l'>
                <label htmlFor="companyEditEmailInvoice" className='m-t-10px'>E-mail para nota fiscal <span style={{
                    color: 'red'
                }}>*</span></label>
                <input type="email"
                       placeholder='Informe o e-mail de envio de notas'
                       id='companyEditEmailInvoice'
                       className='form-control form-control-sm' {...bindEmailInvoice}/>
            </div>
        </div>

        {errors.length > 0 && <div className='m-t-40px'>
            {trail.map((props, index) => (
                <a.div
                    key={index}
                    className='alert alert-danger'
                    style={props}>
                    {errors[index]}
                </a.div>
            ))}
        </div>}

        <div className='t-a-c m-t-40px'>
            <button type='submit'
                    disabled={isUpdating}
                    className='TkSignUpView__plan-button TkSignUpView__plan-button-business'>
                <TkSvgIcon icon={isUpdating ? 'sync-solid' : getIcon(AccountType.business)} className={`m-r-1em ${isUpdating ? 'rotate-1-seg' : ''}`}/>Cadastrar usuário Business
            </button>
        </div>
    </form>
}


const FormPro:FC = () => {
    const history = useHistory()
    const {pathname, search} = useLocation()
    const {completeUserPro} = useTkUser()
    const {isMediaXs, isMediaSm} = useTkMedia()
    const docRef = useRef()
    const phoneRef = useRef()
    const [isUpdating, setUpdating] = useState(false)
    const {bind: bindDoc, value: docValue, setValue: setDoc} = useSimpleInput('')
    const {bind: bindPhone, value: phoneValue, setValue: setPhone} = useSimpleInput('')
    const {bind: bindProfession, value: professionValue} = useSimpleInput('')
    const {bind: bindOccupationArea, value: occupationAreaValue} = useSimpleInput('')
    const [errors, setErrors] = useState([]);

    const config = {mass: 5, tension: 2000, friction: 200}
    const trail = useTrail(errors.length, {
        config,
        opacity: errors.length > 0 ? 1 : 0,
        from: {opacity: 0},
    });

    const onSubmit = useCallback(async (e: any) => {
        e.preventDefault();

        const newState = [];

        if (isBlank(docValue)) newState.push('Preencha o CPF');
        else if (!isCpf(docValue)) newState.push('CPF Inválido');
        if (isBlank(phoneValue)) newState.push('Preencha o telefone');
        if (isBlank(professionValue)) newState.push('Preencha sua profissão');
        if (isBlank(occupationAreaValue)) newState.push('Preencha sua área de ocupação');

        setErrors(newState)

        if (newState.length === 0) {
            setUpdating(true)

            try {
                await completeUserPro({
                    document: docValue,
                    phone: phoneValue,
                    profession: professionValue,
                    occupationArea: occupationAreaValue,
                })

                const p = new URLSearchParams(search)
                p.set('estagio', 'criacao-sucesso')

                history.replace(`${pathname}?${p.toString()}`)
            } catch (e) {
                if (isArray(e)) {
                    e.forEach((obj: any) => newState.push(obj.message));
                    setErrors(newState);
                }
                vibrate()
            } finally {
                setUpdating(false)
            }
        }
    }, [docValue, phoneValue, professionValue, occupationAreaValue, setUpdating, setErrors, pathname, search])


    useEffect(() => {
        let docCleave: Cleave;
        let phoneCleave: Cleave;

        if (docRef.current) {
            docCleave = new Cleave(docRef.current || '', {
                numericOnly: true,
                blocks: [3, 3, 3, 2],
                delimiters: [".", ".", "-"],
                onValueChanged: ({target}) => setDoc(target.value)
            });
        }

        if (phoneRef.current) {
            phoneCleave = new Cleave(phoneRef.current || '', {
                numericOnly: true,
                blocks: [0, 2, 5, 4],
                delimiters: ["(", ") ", "-"],
                onValueChanged: ({target}) => setPhone(target.value)
            });
        }

        return () => {
            if (docCleave) docCleave.destroy();
            if (phoneCleave) phoneCleave.destroy();

            docCleave = null;
            phoneCleave = null;
        }
    }, [docRef.current, phoneRef.current])


    return <form style={{
        minWidth: isMediaXs || isMediaSm ? '100%' : 700
    }} onSubmit={onSubmit} noValidate>
        <div className='row'>
            <div className='col-md-6 t-a-l'>
                <label htmlFor="signUpCompleteDocument">CPF <span style={{
                    color: 'red'
                }}>*</span></label>
                <input type="text"
                       ref={docRef}
                       id='signUpCompleteDocument'
                       required
                       className='form-control form-control-sm' {...bindDoc}/>
            </div>
            <div className='col-md-6 t-a-l'>
                <label htmlFor="signUpCompletePhone">Telefone <span style={{
                    color: 'red'
                }}>*</span></label>
                <input type="tel"
                       ref={phoneRef}
                       id='signUpCompletePhone'
                       required
                       className='form-control form-control-sm'  {...bindPhone}/>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-6 t-a-l'>
                <label htmlFor="signUpCompleteProfessional" className='m-t-10px'>Profissão <span style={{
                    color: 'red'
                }}>*</span></label>
                <input type="text"
                       id='signUpCompleteProfessional'
                       required
                       maxLength={100}
                       className='form-control form-control-sm' {...bindProfession}/>
            </div>
            <div className='col-md-6 t-a-l'>
                <label htmlFor="signUpCompleteArea" className='m-t-10px'>Área de atuação <span style={{
                    color: 'red'
                }}>*</span></label>
                <input type="text"
                       id='signUpCompleteArea'
                       maxLength={100}
                       required
                       className='form-control form-control-sm'  {...bindOccupationArea}/>
            </div>
        </div>

        {errors.length > 0 && <div className='m-t-40px'>
            {trail.map((props, index) => (
                <a.div
                    key={index}
                    className='alert alert-danger'
                    style={props}>
                    {errors[index]}
                </a.div>
            ))}
        </div>}

        <div className='t-a-c m-t-40px'>
            <button type='submit'
                    disabled={isUpdating}
                    className='TkSignUpView__plan-button TkSignUpView__plan-button-pro'>
                <TkSvgIcon icon={isUpdating ? 'sync-solid' : getIcon(AccountType.pro)} className={`m-r-1em ${isUpdating ? 'rotate-1-seg' : ''}`}/>Cadastrar usuário Pro
            </button>
        </div>
    </form>
}


const _CompleteData:FC = () => {
    const {isMediaXs, isMediaSm} = useTkMedia()
    const {search} = useLocation()
    const p = new URLSearchParams(search)
    const type: AccountType = AccountType[p.get('tipo') as keyof typeof AccountType]

    const getForm = useCallback(() => {
        switch (type) {
            case AccountType.pro:
                return <FormPro/>
            case AccountType.business:
                return <FormBusiness/>
        }

        return <div>
            Tipo de usuário não conhecido
        </div>
    }, [type]);

    return <>
        <TkHeadSEO title='Completar cadastro | Plenobras' description='Completar o cadastro Plenobras'/>

        <div className="container m-t-1rem t-a-c">
            <TkLogoBuilder/>

            <h2 className={isMediaXs || isMediaSm ? 'm-t-10px' : 'm-t-30px'}>Complete o seu cadastro para se tornar usuário {getTitle(type)}</h2>

            <div className='TkSignUpView__container m-t-40px'>
                {getForm()}
            </div>

            <TkDivider style={{marginTop: 50}}/>

            <div className='TkSignUpView__footer'>
                <p>{tk_var_teky_name} | {formatDocument(tk_var_teky_document)} | {tk_var_teky_address1}</p>
                <p>Formas de Pagamento: Cartão de crédito, Boleto e Pix</p>
            </div>
        </div>
    </>
}


export default memo(_CompleteData)
