import React, {forwardRef, ForwardRefRenderFunction, useCallback, useImperativeHandle, useState} from "react";
import {List} from "../../../models/list";
import TkModal, {TkModalActions} from "../../../components/particles/TkModal";
import {useSimpleInput} from "../../../hooks/UseSimpleInput";
import {useTkAppErrors, useTkUser} from "../../../context/TkContext";
import {animated as a, useTrail} from "react-spring";
import {isBlank, isEmail} from "../../../utils/string-utils";
import {isArray} from "../../../utils/utils";

export interface ITkModalEditList {
    show(list?: List): void
    close(): void
}

const TkModalEditList:ForwardRefRenderFunction<ITkModalEditList, {
    updateListState(list: List): void
}> = ({updateListState}, ref) => {
    const {updateListData} = useTkUser()
    const {goToPageError} = useTkAppErrors()
    const [list, setList] = useState<List>(null)
    const [errors, setErrors] = useState([])
    const [isSaving, setSaving] = useState(false)
    const [timer, setTimer] = useState(null)
    const {bind: bindName, setValue: setName, value: name} = useSimpleInput('');
    const {bind: bindEmail, setValue: setEmail, value: customerEmail} = useSimpleInput('');
    const {bind: bindBuilding, setValue: setBuilding, value: buildingName} = useSimpleInput('');
    const {bind: bindAddress, setValue: setAddress, value: buildingAddress} = useSimpleInput('');
    const {bind: bindNotes, setValue: setNotes, value: notes} = useSimpleInput('');

    const config = {mass: 5, tension: 2000, friction: 200}
    const trail = useTrail(errors.length, {
        config,
        opacity: errors?.length > 0 ? 1 : 0,
        from: {opacity: 0},
    });

    useImperativeHandle(ref, () => {
        return {
            show(list?: List) {
                const listToEdit  = list || {}

                setList(listToEdit)
                setErrors([])
                setName(listToEdit.name)
                setEmail(listToEdit.customerEmail || '')
                setBuilding(listToEdit.buildingName || '')
                setAddress(listToEdit.buildingAddress || '')
                setNotes(listToEdit.notes || '')
            },
            close() {
                if (!isSaving) setList(null)
            }
        }
    }, [setList, setName, setEmail, setBuilding, setAddress, setNotes])

    const doSubmit = useCallback(async () => {
        const newErrors = [];
        if (isBlank(name)) newErrors.push({
            type: 'danger',
            message: 'Informe o nome da lista'
        })
        if (!isBlank(customerEmail) && !isEmail(customerEmail)) newErrors.push({
            type: 'danger',
            message: 'E-mail inválido'
        })

        if (newErrors.length === 0) {
            setSaving(true)

            try {

                const formData: List = {
                    _id: list._id,
                    name,
                    customerEmail,
                    buildingName,
                    buildingAddress,
                    notes
                }

                const {data} = await updateListData(formData)

                setList(data)

                setErrors([{
                    type: 'success',
                    message: 'Dados da lista salvos com sucesso!'
                }])

                clearTimeout(timer);
                setTimer(setTimeout(() => setErrors([]), 3000))

                updateListState({
                    ...list,
                    ...data
                })

            } catch (e) {
                if (isArray(e.errors)) {
                    newErrors.length = 0
                    e.errors.forEach((obj: any) => newErrors.push({
                        type: 'danger',
                        message: obj.message
                    }));
                    setErrors(newErrors);
                } else goToPageError(e)
            } finally {
                setSaving(false)
            }
        } else {
            setErrors(newErrors)
        }

    }, [updateListData, setList, setErrors, setSaving, name, customerEmail, buildingName, buildingAddress, notes, list])


    return <TkModal show={list !== null}
                    close={() => !isSaving && setList(null)}
                    title={`${list?._id ? 'Editar' : 'Nova'} Lista PRO`}
                    size='lg'>
        <div>
            <div className='form-group'>
                <label htmlFor='editListName'>Nome</label>
                <input type='text'
                       id='editListName'
                       required={true}
                       autoComplete='editListName$$'
                       maxLength={100}
                       placeholder='Nome da lista'
                       className='form-control form-control-sm'
                       {...bindName}/>
            </div>
            <div className='form-group'>
                <label htmlFor='editListCustomerEmail'>E-mail cliente</label>
                <input type='email'
                       autoComplete='editListE$$'
                       id='editListCustomerEmail'
                       placeholder='Informe o e-mail do seu cliente'
                       className='form-control form-control-sm'
                       {...bindEmail}/>
            </div>
            <div className='form-group'>
                <label htmlFor='editListBuildingName'>Nome da obra</label>
                <input type='text'
                       autoComplete='editNO$$'
                       id='editListBuildingName'
                       placeholder='Informe o nome da obra'
                       className='form-control form-control-sm'
                       {...bindBuilding}/>
            </div>
            <div className='form-group'>
                <label htmlFor='editListBuildingAddress'>Endereço da obra</label>
                <input type='text'
                       autoComplete='editEB$$'
                       id='editListBuildingAddress'
                       placeholder='Endereço da obra'
                       className='form-control form-control-sm'
                       {...bindAddress}/>
            </div>
            <div className='form-group'>
                <label htmlFor='editListNotes'>Observações</label>
                <textarea id='editListNotes'
                          style={{minHeight: 100}}
                          maxLength={1000}
                          placeholder='Observações, anotações, dados importantes, descrição da obra, etc.'
                          className='form-control'
                          {...bindNotes}/>
            </div>

            {trail.map((props, index) => (
                <a.div
                    key={index}
                    className={`alert alert-${errors[index].type}`}
                    style={props}>
                    {errors[index].message}
                </a.div>
            ))}
        </div>
        <TkModalActions>
            <button type='button'
                    disabled={isSaving}
                    className='btn btn-secondary'
                    onClick={() => setList(null)}>Fechar
            </button>
            <button type='button'
                    disabled={isSaving}
                    className='btn btn-primary'
                    onClick={doSubmit}>Salva{isSaving ? 'ndo...' : 'r'}</button>
        </TkModalActions>
    </TkModal>
}

export default forwardRef(TkModalEditList)
