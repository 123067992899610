import React, {useCallback, useEffect, useState} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeader from "../../components/particles/TkHeader";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import "./style.scss";
import {Link, useHistory} from "react-router-dom";
import {tk_route_my_orders} from "../TkMyOrdersView";
import {tk_route_my_addresses} from "../TkMyAddressesView";
import {smoothScroll} from "../../utils/utils";
import {tk_route_my_data} from "../TkMyDataView";
import {tk_route_my_lists} from "../TkMyListsView";
import {useTkCart, useTkUser} from "../../context/TkContext";
import {tk_route_companies} from "../TkMyCompaniesView";
import {tk_route_root} from "../TkHomeView";
import {tk_route_my_type} from "../TkMyTypeView";

export const tk_route_account = "/sua-conta";

const TkMyAccountView: React.FC = () => {
  const { isTypePro, isTypeBusiness, me, logout } = useTkUser();
  const [linkToAddresses, setLinkToAddresses] = useState("");

  const { clearCart } = useTkCart();

  const history = useHistory();

  const doLogout = useCallback(async () => {
    await logout();
    clearCart();
    history.push(tk_route_root);
  }, [logout, history]);

  useEffect(() => smoothScroll(), []);

  useEffect(() => {
    (async () => {
      const user = await me();
      if (user && isTypeBusiness()) {
        if (user.defaultCompany)
          setLinkToAddresses(tk_route_my_addresses(user.defaultCompany));
        else setLinkToAddresses(tk_route_companies);
      } else {
        setLinkToAddresses(tk_route_my_addresses());
      }
    })();
  }, [me, setLinkToAddresses]);

  return (
    <>
      <TkHeadSEO title="Sua Conta | Plenobras" />

      <TkHeader />

      <div className="container TkMyAccountView__container">
        <TkBreadcrumb
          list={[
            {
              url: tk_route_account,
              label: "Sua Conta",
            },
          ]}
        />

        <h1>Sua Conta</h1>

        <div className="row m-t-40px">
          <div className="col-12 col-md-6 col-lg-3 m-b-1rem">
            <Link to={tk_route_my_orders} className="TkMyAccountView__card">
              <TkSvgIcon icon="box-solid" />
              <div>
                <h2>Seus pedidos</h2>
                <p>Rastrear, devolver ou comprar produtos novamente</p>
              </div>
            </Link>
          </div>
          {(isTypePro() || isTypeBusiness()) && (
            <div className="col-12 col-md-6 col-lg-3 m-b-1rem">
              <Link to={tk_route_my_lists} className="TkMyAccountView__card">
                <TkSvgIcon icon="list-alt-regular" />
                <div>
                  <h2>Listas Pro</h2>
                  <p>Listas de produtos pro</p>
                </div>
              </Link>
            </div>
          )}
          {isTypeBusiness() && (
            <div className="col-12 col-md-6 col-lg-3 m-b-1rem">
              <Link to={tk_route_companies} className="TkMyAccountView__card">
                <TkSvgIcon icon="building-solid" />
                <div>
                  <h2>Empresas</h2>
                  <p>Alterar minhas empresas</p>
                </div>
              </Link>
            </div>
          )}

          <div className="col-12 col-md-6 col-lg-3 m-b-1rem">
            <Link to={linkToAddresses} className="TkMyAccountView__card">
              <TkSvgIcon icon="map-marker-alt-solid" />
              <div>
                <h2>Endereços</h2>
                <p>Alterar endereços de entrega</p>
              </div>
            </Link>
          </div>

          <div className="col-12 col-md-6 col-lg-3 m-b-1rem">
            <Link to={tk_route_my_data} className="TkMyAccountView__card">
              <TkSvgIcon icon="user-lock-solid" />
              <div>
                <h2>Acesso e segurança</h2>
                <p>Alterar dados pessoais e acesso</p>
              </div>
            </Link>
          </div>

          <div className="col-12 col-md-6 col-lg-3 m-b-1rem">
            <Link to={tk_route_my_type} className="TkMyAccountView__card">
              <TkSvgIcon icon="user-lock-solid" />
              <div>
                <h2>Planos</h2>
                <p>Escolher diferentes opções de contas</p>
              </div>
            </Link>
          </div>
        </div>

        <a
          href="#logout"
          className="TkMyAccountView__logout"
          onClick={() => {
            doLogout();
          }}
        >
          <div>
            <TkSvgIcon icon="sign-out-alt-solid" />
            <span className="TkMyAccountView__logout--color">Sair</span>
          </div>
        </a>
      </div>

      <TkNewsLetter />
      <TkFooter />
    </>
  );
};

export default TkMyAccountView;
