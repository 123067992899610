import React, {useEffect, useState} from "react";
import {ITkProductModel} from "../../models/product";
import {useTkCart, useTkUser} from "../../context/TkContext";
import {MAX_BACKORDER} from "../../utils/constants";
import {formatMoney} from "../../utils/number-utils";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {Link} from "react-router-dom";
import {tk_route_exchanges_and_devolution} from "../TkExchangesAndDevolutionView";
import TkQuantityOptions from "../../components/cart/TkQuantityOptions";
import TkShipmentArea, {TkShipmentAreaType} from "../../components/shipment/TkShipmentArea";
import TkListCombo from "../../components/list/TkListCombo";
import TkAvailabilityCard from "../../components/particles/TkAvailabilityCard";
import TkSkeleton from "../../components/particles/TkSkeleton";

const ProductFloatInfoArea: React.FC<{
  product: ITkProductModel | null | undefined
  quantity: number
  setQuantity: (quantity: number) => void
  add: () => void
  showSkeleton?: boolean
}> = ({product, quantity, setQuantity, add, showSkeleton = false}) => {  

  const {userAuth, isAuth, me} = useTkUser();
  const [showCustomQuantity, setShowCustomQuantity] = useState(false);
  const [isBackorder, setIsBackorder] = useState(false);
  const {updatingCart} = useTkCart();

  const [isSkipMe, setSkipMe] = useState(false);

  const isBackorderOnly = product?.leadTime && !product?.stock

  useEffect(() => {
    if (!isSkipMe && isAuth() && !userAuth.user.defaultAddress) {
      setSkipMe(true);
      (async () => await me())();
    }
  }, [isAuth, me, userAuth, setSkipMe]);

  const onChangeQuantity = (e: any) => {
    setShowCustomQuantity(e.target.value === "more");

    if (e.target.value !== "more") {
      setQuantity(parseInt(e.target.value));
    }
  };

  if(showSkeleton) return <div>
    <div className='TkProductDetailView__sticky'>
      <div className='TkProductDetailView__float'>
        <TkSkeleton width={75} height={25}/>
        <div className='TkProductDetailView__availability'>
          <div className='TkProductDetailView__availability__item'>
            <TkSkeleton width={50}/>
          </div>
        </div>
        <TkSkeleton width={100} height={25}/>
        <TkSkeleton width={141} height={18}/>
        <TkSkeleton width={150} height={21}/>
        <TkSkeleton height={30}/>
        <TkSkeleton height={40}/>
      </div>
      <TkShipmentArea type={TkShipmentAreaType.product_detail} showSkeleton={true} isShowInBalloon={true}/>
    </div>
  </div>

  if (!product) return null

  const inStock = product.stock && product.stock > 0 && (!product.controlMultiplicity || (product.controlMultiplicity && product.stock >= product.minimumSaleQuantity));

  return <div>
    <div className='TkProductDetailView__sticky'>
      <div className='TkProductDetailView__float'>
        <ins className='price'>{formatMoney(product.promoPrice)}</ins>

        <TkAvailabilityCard product={product}/>
        {isBackorderOnly && (
          <div>
            {!isBackorder && 
              <button
                type="button"
                className="btn btn-ice-gray btn-sm"
                onClick={() => setIsBackorder(true)}
              >
                {'Encomendar'}
              </button>
            }
          </div>
        )}

        {showCustomQuantity ?
          <input
            type='number'
            className='quantity form-control form-control-sm'
            min={1}
            max={product?.leadTime ? MAX_BACKORDER : product.stock}
            value={quantity}
            onChange={e => setQuantity(parseInt((e.target.value || '0').replace(/\D+/gi, '')))}
          /> :
          (inStock || isBackorder) && product.price && <select
                  value={quantity}
                  onChange={onChangeQuantity}
                  className='quantity form-control form-control-sm'
              >
              <TkQuantityOptions stock={product?.stock} controlMultiplicity={product?.controlMultiplicity} minimumSaleQuantity={product.minimumSaleQuantity} isLeadTime={product.leadTime > 0}/>
              </select>
        }
        {(!isBackorderOnly || isBackorder) && <>
            <div>
              <span className="f-s-14px">Formas de Pagamento</span><br/>
              <ul className='list-payment'>
                <li><TkSvgIcon icon='visacard'/></li>
                <li><TkSvgIcon icon='mastercard'/></li>
                <li><TkSvgIcon icon='pix'/></li>
                <li><TkSvgIcon icon='boleto'/></li>
              </ul>
            </div>
            {product.isActive ?
              <button
                type='button'
                disabled={updatingCart}
                className='button-2'
                onClick={add}
              >
                {updatingCart ? <TkSvgIcon icon='sync-solid' className='rotate-1-seg m-r-1em'/> :
                  <TkSvgIcon icon='shopping-cart-solid' className='m-r-1em'/>}
                {updatingCart ? 'Adicionando ao carrinho...' : 'Adicionar ao carrinho'}
              </button> : <p/>
            }
          </>
        }

        <TkListCombo product={product} className='TkProductDetailView__listCombo'/>

        <p className='TkProductDetailView__policy'>
          Consulte nossa política de <Link to={tk_route_exchanges_and_devolution}>Trocas e Devoluções</Link>
        </p>
      </div>

      <div className='m-t-20px'>
        <TkShipmentArea product={product}
                        quantity={quantity}
                        type={TkShipmentAreaType.product_detail}
                        isShowInBalloon={true}/>
      </div>

    </div>
  </div>
}

export default ProductFloatInfoArea;