import React, {FC, memo, useEffect, useState} from "react";

const TkReticenceLoading:FC<{
    label?: string
}> = ({label}) => {
    const [dots, setDots] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prevDots => (prevDots + 1) % 4);
        }, 500); // Update every 500ms

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return <>
        {label}{'.'.repeat(dots)}
    </>;
}


export default memo(TkReticenceLoading);