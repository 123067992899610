import React, {useCallback, useEffect, useState} from 'react';
import {interactiveFormatPostalCode, isBlank, onlyNumbers} from "../../utils/string-utils";
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import {useTkDeakContext, useTkLocalization} from "../../context/TkContext";
import {City, State} from "../../models/localization";
import TkReticenceLoading from "../../components/particles/TkReticenceLoading";
import {animated as a, useTrail} from "react-spring";

const _StepCustomerAddress: React.FC<{
    next: () => void;
    back: () => void;
    isInOrOut: boolean;
  }> = ({next, back, isInOrOut = true}) => {
    const {customer, setCustomer} = useTkDeakContext()
    const {getStates, getCities, searchPostalCode} = useTkLocalization()
    const [states, setStates] = useState<State[]>([])
    const [selectedState, setSelectedState] = useState<string>(null)
    const [cities, setCities] = useState<City[]>([])
    const [isLoadingCities, setLoadingCities] = useState<boolean>(false)
    const [isLoadingPostalCode, setLoadingPostalCode] = useState<boolean>(false)
    const [errors, setErrors] = useState<string[]>([]);
    const config = {mass: 5, tension: 2000, friction: 200};
    const trail = useTrail(errors.length, {
        config,
        opacity: errors.length > 0 ? 1 : 0,
        from: {opacity: 0},
    });

    const {
        value: postalCode,
        setValue: setPostalCode,
    } = useSimpleInput("");

    const {
        value: location,
        bind: bindLocation,
        setValue: setLocation,
    } = useSimpleInput("");

    const {
        value: locationNumber,
        bind: bindLocationNumber,
        setValue: setLocationNumber,
    } = useSimpleInput("");

    const {
        value: locationNotes,
        bind: bindLocationNotes,
        setValue: setLocationNotes,
    } = useSimpleInput("");

    const {
        value: neighborhood,
        bind: bindNeighborhood,
        setValue: setNeighborhood,
    } = useSimpleInput("");

    const {
        value: city,
        bind: bindCity,
        setValue: setCity,
    } = useSimpleInput("");

    useEffect(() => {
        getStates().then((states) => {
            setStates(states)
        })
        if (customer?.address) {
            setLocation(customer.address.location)
            setLocationNumber(customer.address.locationNumber)
            setLocationNotes(customer.address.locationNotes)
            setNeighborhood(customer.address.neighborhoodName)
            setPostalCode(interactiveFormatPostalCode(customer.address.postalCode))
            setSelectedState(customer.address.stateAcronym)
            setCity(customer.address.cityName)
        }
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            const searchQuery = onlyNumbers(postalCode)
            if (searchQuery.length === 8 && searchQuery != onlyNumbers(customer?.address?.postalCode || '')) {
                setLoadingPostalCode(true)
                searchPostalCode(searchQuery).then((address) => {
                    if (address) {
                        setLocation(address.location)
                        setNeighborhood(address.neighborhood)
                        setSelectedState(address.stateAcronym)
                        setCity(address.cityName)
                    }
                }).finally(() => setLoadingPostalCode(false))
            }
        }, 500)

        return () => clearTimeout(timeout)
    }, [postalCode, setLoadingPostalCode]);

    const changeState = useCallback(() => {
        setCities([])
        if (selectedState) {
            const currentValue = city
            const state = states.find((state) => state.acronym === selectedState)
            setLoadingCities(true)
            setCity('Carregando cidades...')
            getCities(state._id).then((cities) => {
                setCities(cities || [])
            }).finally(() => {
                setLoadingCities(false)
                setCity(currentValue)
            })
        }
    }, [states, selectedState, city, getCities, setCities, setLoadingCities, setCity])

    const confirmData = (e: React.FormEvent) => {
        e.preventDefault();


        setErrors([])
        const newErrors = []
        if (onlyNumbers(postalCode).length !== 8) newErrors.push("CEP inválido")
        if (isBlank(location)) newErrors.push("Informe o endereço")
        if (isBlank(neighborhood)) newErrors.push("Informe o bairro")
        if (isBlank(city)) newErrors.push("Informe a cidade")
        if (isBlank(selectedState)) newErrors.push("Informe o estado")

        if (newErrors.length === 0) {
            const freshCustomer = customer || {
                address: {
                    location: '',
                    locationType: '',
                    locationNumber: '',
                    locationNotes: '',
                    postalCode: '',
                    neighborhoodName: '',
                    cityName: '',
                    stateAcronym: ''
                }
            }

            freshCustomer.address = {
                location,
                locationNumber,
                locationNotes,
                postalCode,
                neighborhoodName: neighborhood,
                cityName: city,
                stateAcronym: selectedState
            }

            setCustomer(freshCustomer)
            next()
        } else {
            setErrors(newErrors)
        }
    }


    return <form onSubmit={confirmData} className='TkMakeDeakOrderView__form'>
        <input type="text"
               className='form-control'
               value={postalCode}
               onChange={(e) => {
                   setPostalCode(interactiveFormatPostalCode(e.target.value))
               }}
               maxLength={9}
               placeholder='Informe o CEP'/>
        {isLoadingPostalCode && <small><TkReticenceLoading label='Carregando CEPs'/></small>}

        <input type="text"
               className='form-control TkMakeDeakOrderView__input'
               maxLength={100}
               disabled={isLoadingPostalCode}
               placeholder='Informe a rua' {...bindLocation}/>

        <input type="text"
               className='form-control TkMakeDeakOrderView__input'
               maxLength={50}
               placeholder='Informe o número' {...bindLocationNumber}/>

        <input type="text"
               className='form-control TkMakeDeakOrderView__input'
               maxLength={100}
               placeholder='Informe o complemento' {...bindLocationNotes}/>

        <input type="text"
               className='form-control TkMakeDeakOrderView__input'
               maxLength={100}
               disabled={isLoadingPostalCode}
               placeholder='Informe o bairro' {...bindNeighborhood}/>

        <select className='form-control TkMakeDeakOrderView__input'
                disabled={isLoadingPostalCode}
                value={selectedState}
                onChange={(e) => {
                    setSelectedState(e.target.value)
                    changeState()
                }}>
            <option value='' label='Selecione o estado'/>
            {states.map((state) => (
                <option key={state.acronym} value={state.acronym} label={state.name}/>
            ))}
        </select>

        <input type="text"
               className='form-control TkMakeDeakOrderView__input'
               maxLength={100}
               disabled={isLoadingCities || isLoadingPostalCode}
               list='citysOfState'
               placeholder='Informe a cidade' {...bindCity}/>
        <datalist id='citysOfState'>
            {cities.map((city) => (
                <option key={city._id} value={city.name}/>
            ))}
        </datalist>

        {trail.map((props, index) => (
            <a.div key={index} className="alert alert-danger" style={props}>
                {errors[index]}
            </a.div>
        ))}

        <div className='TkMakeDeakOrderView__button-container'>
            <button className='btn btn-secondary'
                    onClick={() => back()}
                    type='button'>Voltar
            </button>
            <button className='btn btn-primary'
                    type='submit'>Salvar e definir preços
            </button>
        </div>
    </form>
};


export default _StepCustomerAddress;
