import {createContext} from "react";
import {List} from "../../models/list";

export interface _ListContextType {
    lists: List[]
    list: List
    setLists: (lists: List[]) => void
    setList: (list: List) => void
    loadLists: () => void
    loadList: (listId: string) => void
    isLoadingLists: boolean
    isLoadingList: boolean
    isProductInList: (productId: string) => boolean
    isUpdatingProductInList: (productId: string) => boolean
    productQuantityInList: (productId: string) => number
    updateProductInList: (productId: string, quantity: number) => void
    removeProductFromList: (productId: string) => void
}

const _ListContext = createContext<_ListContextType>({
    lists: [],
    list: null,
    setLists: () => {},
    setList: () => {},
    loadLists: () => {},
    loadList: () => {},
    isLoadingLists: false,
    isLoadingList: false,
    isUpdatingProductInList: ()=> false,
    isProductInList: () => false,
    productQuantityInList: () => 0,
    updateProductInList: () => {},
    removeProductFromList: () => {}
})

export default _ListContext