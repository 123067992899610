import React, {useEffect} from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {useTkAppErrors} from "../../context/TkContext";
import SomethingWrong from '../../assets/images/alguma-coisa-deu-errado.jpg';
import TkLazyImage from "../../components/particles/TkLazyImage";
import {Link, useHistory} from "react-router-dom";
import {tk_route_root} from "../TkHomeView";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {vibrate} from "../../utils/utils";
import {sendException} from "../../utils/analytics-utils";

export const tk_route_something_wrong = '/alguma-coisa-ocorreu-errado';

const Tk500View: React.FC = () => {
    const {currentError} = useTkAppErrors();
    const history = useHistory();

    useEffect(() => {
        vibrate(200);
        if (currentError) {
            sendException(`${currentError.code} - ${currentError.messages.join(', ')}`)
            const {defaultError: {message, fileName, lineNumber}} = currentError

            console.error(`Alguma Coisa Ocorreu Errada [${JSON.stringify({message, fileName, lineNumber})}]`, currentError.defaultError)
        }
    }, []);

    return <>
        <TkHeadSEO title='Alguma Coisa Ocorreu Errada | Plenobras'/>

        <TkHeader/>

        <TkDivisionsTop/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_something_wrong,
                    label: 'Alguma Coisa deu Errada'
                }
            ]}/>

            <h1>Alguma coisa deu errada</h1>

            <div className="row">
                <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                    <p><TkLazyImage src={SomethingWrong} className='img-responsive' alt='Alguma coisa deu errado'/></p>
                </div>
                <div className="col-12 col-sm-7 col-md-8 col-lg-9">
                    {currentError && <>
                        <br/>
                        <br/>
                        <br/>
                        <p>Código do erro: <span className='f-w-b'>{currentError.code}</span></p>
                        <p>Descrição do erro: {currentError.messages.map((e, idx) => <span key={idx}
                                                                                           className='f-w-b'>{e}</span>)}</p>
                    </>}
                    <br/>
                    <br/>
                    <br/>
                    <p className='t-a-c'>
                        <Link to={tk_route_root} className='btn btn-secondary m10px'>
                            <TkSvgIcon icon='home-solid' className='m-r-1rem'/>
                            Voltar à tela principal
                        </Link>
                        <button type='button' onClick={history.goBack} className='btn btn-primary m10px'>
                            <TkSvgIcon icon='chevron-left-solid' className='m-r-1rem'/>
                            Apenas voltar
                        </button>
                    </p>
                </div>
            </div>
        </div>
        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default Tk500View
