import './style.scss'
import React, {FC, memo, useCallback, useState} from "react";
import TkSvgIcon from "../TkSvgIcon";
import whatsBg from '../../../assets/images/whatsapp-bg-pattern.webp'
import {tk_var_phone1} from "../../../views/TkBeInTouchView";
import {isBlank} from "../../../utils/string-utils";
import {useTkWhatsAppBalloon} from "../../../context/TkContext";

const TkWhatsAppBalloon:FC = () => {
    const [isClicked, setClicked] = useState(false)
    const {setShowBalloon, isShowBalloon, isShowIcon} = useTkWhatsAppBalloon()
    const [message, setMessage] = useState('')
    const svgCorner = <svg viewBox="0 0 8 13" height="13" width="8" x="0px" y="0px">
        <path opacity="0.13" fill="#0000000" d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"/>
        <path fill="currentColor" d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"/>
    </svg>

    const actions = [
        'Dúvidas técnicas sobre produtos',
        'Informações sobre um pedido',
        'Falar com um atendente',
    ]

    const buildLink = useCallback((msg: string) => {
        return `https://api.whatsapp.com/send/?phone=${tk_var_phone1(false)}&text=${encodeURIComponent(msg)}`
    }, [])

    const click = useCallback(() => {
        setClicked(true)
        setShowBalloon(!isShowBalloon)
    }, [setClicked, setShowBalloon, isShowBalloon])

    const send = useCallback(() => {
        if (!isBlank(message)) {
            window.open(buildLink(message), '_blank')
            setMessage('')
        }
    }, [message, setMessage])

    let style = {}

    if (isShowBalloon) style = {
        //Evita que o background seja carregado no load da página, carregando somente ao chamar o Whats
        backgroundImage: `url(${whatsBg})`
    }

    return <>
        <div className={`TkWhatsAppBalloon__container ${isShowIcon ? 'active' : ''}`}>
            <span
                className={`TkWhatsAppBalloon__button ${isShowBalloon ? 'TkWhatsAppBalloon__button--disable' : ''} ${isClicked ? 'TkWhatsAppBalloon__button--hide' : ''}`}
                title='Fale conosco pelo WhatsApp'
                onClick={click}>
                <TkSvgIcon icon="whatsapp-brands"/>
            </span>
            <span
                className={`TkWhatsAppBalloon__hello ${isClicked ? 'TkWhatsAppBalloon__hello--hide' : ''}`}>Olá!</span>
        </div>
        <div className={`TkWhatsAppBalloon__chat ${isShowBalloon ? 'TkWhatsAppBalloon__chat--active' : ''}`} style={style}>
            <div className='TkWhatsAppBalloon__header'>
                <TkSvgIcon icon='pleno-symbol' className='logo'/>
                <span className='title'>Plenobras<br/><small>Online</small></span>
                <span className='close' onClick={() => setShowBalloon(false)}>
                    <TkSvgIcon icon="close" />
                </span>
            </div>
            <div className='TkWhatsAppBalloon__body'>
                <div className='TkWhatsAppBalloon__message'>
                    {svgCorner}
                    <p>Bem vindo a Plenobras! Aqui você encontra toda a linha de materiais elétricos, hidráulicos e MRO.</p>
                </div>

                <div className='TkWhatsAppBalloon__message'>
                    {svgCorner}
                    <p>
                        O que você deseja?<br/>
                        <span className='t-a-c'>
                            {actions.map(a => <a key={a} href={buildLink(a)} target='_blank'>{a}</a>)}
                        </span>
                    </p>
                </div>
                <br/>
                <br/>
                <br/>

                <div className='TkWhatsAppBalloon__shell-container'>
                    <div className='TkWhatsAppBalloon__shell'>
                        <TkSvgIcon icon='face-smile' className='smile'/>
                        <input type='text' className='text' value={message} onChange={e => setMessage(e.target.value)} placeholder='Mensagem'/>
                    </div>
                    <button type='button' onClick={send}>
                        <TkSvgIcon icon='paper-plane'/>
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default memo(TkWhatsAppBalloon)
