import React from "react";
import {Link, useLocation} from 'react-router-dom';
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {tk_route_root} from "../TkHomeView";

export const tk_route_401 = '/permissao-negada';

const Tk404View: React.FC = () => {

    const location = useLocation();

    return <>
        <TkHeadSEO title='Permissão negada | Plenobras'/>

        <TkHeader/>

        <TkDivisionsTop/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_401,
                    label: 'Permissão negada'
                }
            ]}/>

            <h1>Permissão negada</h1>

            <p>Você não possui permissão de acesso a esse recurso <code>{location.pathname}</code>.</p>

            <p><Link to={tk_route_root} title='Voltar a tela inicial do site'>Volte para o início do site</Link>.</p>
        </div>

        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default Tk404View
