import dayjs from "dayjs";

const _FULL_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ'

export enum DateFormats {
    ddMMyyyy,
    ddMMyyyyHHmm,
}

const calcDateShipment = (days: number, format = 'DD/MM') => {
    const now = new Date();
    now.setDate(now.getDate() + days);

    return dayjs(now).format(format);
}

const parseDate = (date: string | Date): dayjs.Dayjs => {
    return dayjs(date, _FULL_FORMAT)
}

const formatDateFromString = (date: string | Date, format: DateFormats = DateFormats.ddMMyyyy) => {
    const d = dayjs(date).isValid() ? dayjs(date) : parseDate(date);

    switch (format) {
        case DateFormats.ddMMyyyyHHmm:
            return d.format('DD/MM/YYYY HH:mm')
        case DateFormats.ddMMyyyy:
        default:
            return d.format('DD/MM/YYYY')
    }
}

function formatRelativeDate(date: string | Date) {
    if (!date) return '';
    const now = dayjs();
    const diffInSeconds = now.diff(date, 'second');

    const intervals = [
        { label: 'ano', seconds: 31536000 },
        { label: 'mês', seconds: 2592000 },
        { label: 'dia', seconds: 86400 },
        { label: 'hora', seconds: 3600 },
        { label: 'minuto', seconds: 60 },
        { label: 'segundo', seconds: 1 },
    ];

    for (const interval of intervals) {
        const count = Math.floor(diffInSeconds / interval.seconds);
        if (count >= 1) {
            const plural = count > 1 ? (interval.label === 'mês' ? 'meses' : `${interval.label}s`) : interval.label;
            return `há ${count} ${plural}`;
        }
    }

    return 'agora mesmo';
}

const isBefore = (from: string | Date, to: string | Date) => dayjs(from, _FULL_FORMAT).isBefore(dayjs(to, _FULL_FORMAT))

export {
    calcDateShipment,
    isBefore,
    parseDate,
    formatDateFromString,
    formatRelativeDate
}
