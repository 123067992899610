import React, {useCallback, useEffect, useState} from 'react';
import {useTkDeakContext} from "../../context/TkContext";
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import {animated as a, useTrail} from "react-spring";
import {interactiveFormatDocument, isBlank, isCpfOfCnpj, isEmail, onlyNumbers} from "../../utils/string-utils";
import {COMMONS_EMAIL_DOMAINS} from "../../utils/constants";

const _stepCustomerData: React.FC<{
    next: () => void;
    back: () => void;
    isInOrOut: boolean;
}> = ({next, back }) => {
    const {customer, setCustomer} = useTkDeakContext();
    const [errors, setErrors] = useState<string[]>([]);
    const config = {mass: 5, tension: 2000, friction: 200};
    const trail = useTrail(errors.length, {
        config,
        opacity: errors.length > 0 ? 1 : 0,
        from: {opacity: 0},
    });

    const {
        value: document,
        setValue: setDocument,
    } = useSimpleInput("");

    const {
        value: name,
        bind: bindName,
        setValue: setName,
    } = useSimpleInput("");

    const {
        value: email,
        bind: bindEmail,
        setValue: setEmail,
    } = useSimpleInput("");

    const {
        value: phone,
        setValue: setPhone,
    } = useSimpleInput("");


    const confirmData = (e: React.FormEvent) => {
        e.preventDefault();

        setErrors([])
        const newErrors:string[] = [];

        if (isBlank(document) || !isCpfOfCnpj(document)) newErrors.push("CPF ou CNPJ inválido")
        if (isBlank(name)) newErrors.push( "Nome do cliente inválido");
        if (isBlank(email) || !isEmail(email)) newErrors.push("E-mail do cliente inválido");
        if (isBlank(phone)) newErrors.push("Telefone do cliente inválido");

        if (newErrors.length === 0) {
            const freshCustomer = customer || {address: {
                location: '',
                locationType: '',
                locationNumber: '',
                locationNotes: '',
                postalCode: '',
                neighborhoodName: '',
                cityName: '',
                stateAcronym: ''
            }}

            setCustomer({
                ...freshCustomer,
                document,
                name,
                email,
                phone,
                nickname: name,
            })
            next();
        } else {
            setErrors(newErrors)
        }
    }

    useEffect(() => {
        if (customer) {
            setDocument(customer.document || '')
            setName(customer.name || '')
            setEmail(customer.email || '')
            setPhone(customer.phone || '')
        }
    }, [customer]);

    const emailOptions = useCallback(() => {
        if (!email.includes('@')) return []

        const [user, domain] = email.split('@')

        if (!isBlank(user) && COMMONS_EMAIL_DOMAINS.some(d => d === domain)) return []

        return COMMONS_EMAIL_DOMAINS
            .filter((domain) => domain.startsWith(domain.toLowerCase()))
            .map((domain) => `${user.toLowerCase()}@${domain}`)
    }, [email])

    const clientData = () => {
        return <form onSubmit={confirmData} className='TkMakeDeakOrderView__form'>
            <input type="text"
                   className='form-control'
                   value={document}
                   onChange={(e) => {
                       setDocument(interactiveFormatDocument(e.target.value))
                   }}
                   maxLength={18}
                   placeholder='Informe o CPF ou CNPJ' />

            <input type="text"
                   className='form-control TkMakeDeakOrderView__input'
                   maxLength={100}
                   placeholder='Informe o nome do cliente' {...bindName}/>

            <input type="email"
                   className='form-control TkMakeDeakOrderView__input'
                   list='emailOptions'
                   maxLength={100}
                   placeholder='Informe o e-mail do cliente' {...bindEmail}/>
            <datalist id='emailOptions'>
                {emailOptions().map((email, index) => (
                    <option key={index} value={email} label={email}/>
                ))}
            </datalist>

            <input type="text"
                   value={phone}
                   onChange={(e) => {
                       setPhone(onlyNumbers(e.target.value))
                   }}
                   maxLength={16}
                   className='form-control TkMakeDeakOrderView__input'
                   placeholder='Informe o telefone do cliente'/>

            <br/>
            {trail.map((props, index) => (
                <a.div key={index} className="alert alert-danger" style={props}>
                    {errors[index]}
                </a.div>
            ))}

            <div className='TkMakeDeakOrderView__button-container'>
                <button className='btn btn-secondary'
                        onClick={() => back()}
                        type='button'>Voltar
                </button>
                <button className='btn btn-primary'
                        type='submit'>Salvar e ir para o endereço
                </button>
            </div>
        </form>
    }

    return clientData();
};


export default _stepCustomerData;
