import React, {FC, memo} from "react";
import {AccountType} from "../../models/user";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {Link, useLocation} from "react-router-dom";
import {useTkMedia} from "../../context/TkContext";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import {TkLogoBuilder} from "../../components/particles/TkLogoVector"
import TkDivider from "../../components/particles/TkDivider"
import {tk_var_teky_address1, tk_var_teky_document, tk_var_teky_name} from "../TkBeInTouchView"
import {formatDocument} from "../../utils/string-utils"
import {animated as a, useTrail} from "react-spring"
import {cardsContent, getIcon, getTitle} from './_utils'


const Card:FC<{
    type: AccountType
    title: string
    defaultFeatures: string[]
    plusFeatures?: string[]
}> = ({type, title, defaultFeatures, plusFeatures}) => {
    const {pathname, search} = useLocation()
    const p = new URLSearchParams(search)
    p.set('estagio', 'email')
    p.set('tipo', type)

    return <>
        <header>
            <TkSvgIcon icon={getIcon(type)}/>
            <h2>{title}</h2>
        </header>
        <div className='TkSignUpView__card-body'>
            <ul>
                {defaultFeatures.map((df, idx) => {
                    return <div key={idx}>
                    {idx === 0 && (type === AccountType.standard || type === AccountType.pro) && <li>
                        <TkSvgIcon icon='check-solid' className='TkSignUpView__icon-size-1' /><b>Compras com CPF</b>
                    </li>}
                    {idx === 0 && type === AccountType.business && <li>
                        <TkSvgIcon icon='check-solid' className='TkSignUpView__icon-size-1' /><b>Compras com CNPJ</b>
                    </li>}
                    <li key={type + idx}>
                        <TkSvgIcon icon='check-solid' className='TkSignUpView__icon-size-1'/><span>{df}</span>
                    </li>
                    </div>
                })}
            </ul>
            {plusFeatures && <div className='t-a-l m-t-20px'>
                <div className='m-b-20px plus-more'>
                    <TkSvgIcon icon='plus-solid' className='TkSignUpView__icon-size-1'/>&nbsp;&nbsp;&nbsp;e mais
                </div>
                <ul>
                    {plusFeatures.map((pf, idx) => {
                        return <li key={type + pf + idx}>
                            <TkSvgIcon icon='check-solid' className='TkSignUpView__icon-size-1'/><span>{pf}</span>
                        </li>
                    })}
                </ul>
            </div>}
        </div>

        <Link to={`${pathname}?${p.toString()}`}
              title={`Criar ${title}`}
              className={`TkSignUpView__plan-button plan-button-${type}`}>Quero ser {getTitle(type)} <TkSvgIcon icon='chevron-right-solid'/></Link>
    </>
}


const _ChooseTypeAccount:FC = () => {
    const {isMediaXs, isMediaSm} = useTkMedia()

    const config = {mass: 5, tension: 2000, friction: 200}
    const trail = useTrail(cardsContent.length, {
        config,
        from: {
            opacity: 0,
            transform: `translate3d(0,40px,0)`
        },
        to: {
            opacity: 1,
            transform: 'translate3d(0,0,0)'
        }
    });

    return <>
        <TkHeadSEO title='Cadastro | Plenobras' description='Faça seu cadastro na Plenobras e compre online de forma rápida e segura'/>

        <div className="container m-t-1rem t-a-c">
            <TkLogoBuilder/>

            <h2 className={isMediaXs || isMediaSm ? 'm-t-10px' : 'm-t-30px'}>Bem-vindo! Selecione seu tipo de conta:</h2>


            <div className='TkSignUpView__container m-t-40px'>

                {trail.map((props, index) => {
                    const c = cardsContent[index];
                    return <a.article style={props}
                                      key={index}
                                      className={`TkSignUpView__card ${c.type}`}>
                        <Card key={c.type}
                              type={c.type}
                              title={c.title}
                              defaultFeatures={c.defaultFeatures}
                              plusFeatures={c.plusFeatures}/>
                    </a.article>
                })}
            </div>

            <TkDivider style={{marginTop: 50}}/>

            <div className='TkSignUpView__footer'>
                <p>{tk_var_teky_name} | {formatDocument(tk_var_teky_document)} | {tk_var_teky_address1}</p>
                <p>Formas de Pagamento: Cartão de crédito, Boleto e Pix</p>
            </div>
        </div>
    </>

}


export default memo(_ChooseTypeAccount)
