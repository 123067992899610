import React, {useEffect, useMemo, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import "./style.scss";
import {useTkAppErrors, useTkDeakContext, useTkMedia, useTkUser} from "../../context/TkContext";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkFooter from "../../components/particles/TkFooter";
import {isBlank} from "../../utils/string-utils";
import TkStepper from "../../components/particles/TkStepper";
import {isArray} from "../../utils/utils";
import StepCustomerLookup from "./_stepCustomerLookup"
import StepCustomerData from "./_stepCustomerData"
import StepCustomerAddress from "./_stepCustomerAddress"
import StepChangePrices from "./_stepChangePrices"
import StepMakeOrder from "./_stepMakeOrder"
import TkSellerHeader from "../../components/particles/TkSellerHeader";

const DEFAULT_PATH = '/orcamento-deak';

export const tk_route_deak_order = (listId?: string, step?: number) => {
    if (isBlank(listId)) return DEFAULT_PATH;
    const params = new URLSearchParams();
    if (!isBlank(listId)) params.set('listId', listId);
    if (step) params.set('step', step.toString());

    return `${DEFAULT_PATH}?${params.toString()}`;
}

const TkMakeDeakOrderView: React.FC = () => {
    const history = useHistory()
    const {search} = useLocation()
    const {isMediaXs} = useTkMedia()
    const {setList, setLoadingList, list} = useTkDeakContext()
    const {goToPageError} = useTkAppErrors()
    const [_, setMessageError] = useState<string>(null);
    const [lastIndex, setLastIndex] = useState(-1);
    const [labels, setLabels] = useState<string[]>([]);
    const [components, setComponents] = useState<any[]>([]);
    const {isAuth, getList} = useTkUser();

    const activeIndex = useMemo(() => {
        const p = new URLSearchParams(search)
        return parseInt(p.get('step') || '0')
    }, [search])

    function next() {
        if (list) {
            setLastIndex(activeIndex);
            history.push(tk_route_deak_order(list._id, activeIndex + 1))
        }
    }

    function back() {
        if (list) {
            setLastIndex(activeIndex);
            history.push(tk_route_deak_order(list._id, activeIndex - 1))
        }
    }

    useEffect(() => {
        const p = new URLSearchParams(search)
        const listId = p.get('listId')

        if (!isBlank(listId)) {
            setMessageError(null)
            setLoadingList(true)
            getList(listId)
                .then(({success, data}) => {
                    if (success) setList(data)
                })
                .catch(e => {
                    if (e.errors && isArray(e.errors)) {
                        const msg = e.errors.reduce((obj: any, val: any) => {
                            obj += val.message
                            return obj;
                        }, '')

                        setMessageError(msg)
                    } else goToPageError(e)
                })
                .finally(() => setLoadingList(false))
        }
    }, [search, goToPageError])

    useEffect(() => {
        if (isAuth()) {
            const Labels = ['Informe o documento do cliente', 'Dados do Cliente', 'Endereço de entrega', 'Preços', 'Finalização']
            const Components = [
                <StepCustomerLookup next={next} isInOrOut={activeIndex > lastIndex}/>,
                <StepCustomerData next={next} back={back} isInOrOut={activeIndex > lastIndex}/>,
                <StepCustomerAddress next={next} back={back} isInOrOut={activeIndex > lastIndex}/>,
                <StepChangePrices next={next} back={back} isInOrOut={activeIndex > lastIndex}/>,
                <StepMakeOrder next={next} back={back}/>
            ]

            setLabels(Labels)
            setComponents(Components)
        }
    }, [isAuth, list, setLabels, setComponents, activeIndex, lastIndex])

    const form = useMemo(() => components[activeIndex], [components, activeIndex])

    return <>
        <TkHeadSEO title="Geração de pedido deak"/>
        <TkSellerHeader/>
        <div className={`TkMakeDeakOrderView__container Step_${activeIndex} m-t-20px`}>
            <div className="steppers">
                {isMediaXs && form}
                {<TkStepper labels={labels} activeIndex={activeIndex}/>}
                {!isMediaXs && form}
            </div>
        </div>

        <TkFooter/>
    </>
};

export default TkMakeDeakOrderView;



 