import React, {FC, useEffect, useState} from "react";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {Link, useHistory, useLocation} from "react-router-dom";
import {isBlank} from "../../utils/string-utils";
import {tk_route_seller_list_manager_view} from "./index";


const _ProductFilters:FC = () => {
    const {search, pathname} = useLocation()
    const history = useHistory()
    const params = new URLSearchParams(search)
    const [term, setTerm] = useState('')

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const currentTerm = params.get('term')

        if (currentTerm === term && !params.has('from')) return

        params.delete('term')
        params.delete('from')
        if (!isBlank(term)) params.set('term', term)
        history.push(`${pathname}?${params.toString()}`)
    }

    useEffect(() => {
        setTerm(params.get('term'))
    }, [search]);

    return <form onSubmit={onSubmit} className='TkSellerListManagerView__product-filters'>
        <input type='search'
               className='form-control'
               value={term}
               onChange={e => setTerm(e.target.value)}
               placeholder='O que procura...'/>
        <button type='submit'
                className='TkSellerListManagerView__pickup-list m-t-10px'>Procurar produtos <TkSvgIcon
            icon='search'/></button>
        <br/>
        <br/>
        <Link to={tk_route_seller_list_manager_view(params.get('listId'))} onClick={() => setTerm('')}>Limpar filtros</Link>
    </form>
}


export default _ProductFilters