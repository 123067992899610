import {useTkAppErrors, useTkUser} from "../context/TkContext";
import {useCallback, useState} from "react";
import {FileTypes} from "../models/file";
import {downloadExcelFile, downloadPdfFile} from "../utils/http-utils";
import {sendAnalyticsEvent} from "../utils/analytics-utils";
import {List} from "../models/list";

export const useListDownload = (list: List, fileType: FileTypes) => {
    const {getListAsFile} = useTkUser()
    const {goToPageError} = useTkAppErrors()
    const [isDownloading, setDownloading] = useState(false)

    const download = useCallback(async () => {

        setDownloading(true)
        try {
            const {data: {base64, extension, fileName}} = await getListAsFile(list._id, fileType)
            if (fileType === FileTypes.PDF) await downloadPdfFile(base64, fileName, extension);
            else if (fileType === FileTypes.XLSX) await downloadExcelFile(base64, fileName, extension);
        } catch (e) {
            goToPageError(e)
        } finally {
            setDownloading(false)
            sendAnalyticsEvent('Download Arquivo', 'List Pro', FileTypes[fileType])
        }
    }, [getListAsFile, goToPageError, setDownloading, list])


    return {
        download,
        isDownloading
    }
}
