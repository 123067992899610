const moneyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

const numberFormat = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
});

const formatMoney = (value: number | undefined | null): string => {
    if (!value) return moneyFormatter.format(0);

    return moneyFormatter.format(value);
};

const formatNumber = (value: number | undefined | null): string => {
    if (!value) return numberFormat.format(0);

    return numberFormat.format(value);
}

const valueToNumber = (value: string): number => {
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
}

export {
    formatMoney,
    formatNumber,
    valueToNumber,
}
