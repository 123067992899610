import {TkCustomerDeakType} from "../../models/order";
import {List, TkProductListDeakPriceType} from "../../models/list";


export interface TkDeakState {
    shipmentCost: string;
    customer?: TkCustomerDeakType
    list?: List
    prices?: TkProductListDeakPriceType
    isLoadingList: boolean
    isMakingOrder: boolean
}

export enum TkDeakReducerType {
    setCustomer,
    setList,
    setIsLoadingList,
    setPrices,
    setIsMakingOrder,
    clearProcess,
    setShipmentCost
}

interface SetCustomerAction {
    type: TkDeakReducerType.setCustomer
    payload: TkCustomerDeakType
}
interface SetListAction {
    type: TkDeakReducerType.setList
    payload: List
}

interface SetIsLoadingListAction {
    type: TkDeakReducerType.setIsLoadingList
    payload: boolean
}

interface SetPricesAction {
    type: TkDeakReducerType.setPrices
    payload: TkProductListDeakPriceType
}

interface SetIsMakingOrderAction {
    type: TkDeakReducerType.setIsMakingOrder
    payload: boolean
}

interface ClearProcessAction {
    type: TkDeakReducerType.clearProcess
}

interface SetShipmentCostAction {
    type: TkDeakReducerType.setShipmentCost
    payload: string
}


type TkDeakReducerAction = SetCustomerAction | SetListAction | SetIsLoadingListAction | SetPricesAction | SetIsMakingOrderAction | ClearProcessAction | SetShipmentCostAction

const TkDeakReducer = (state: TkDeakState, action: TkDeakReducerAction): TkDeakState => {
    switch (action.type) {
        case TkDeakReducerType.setCustomer:
            return {
                ...state,
                customer: action.payload
            }
        case TkDeakReducerType.setList:
            return {
                ...state,
                list: action.payload
            }
        case TkDeakReducerType.setIsLoadingList:
            return {
                ...state,
                isLoadingList: action.payload
            }
        case TkDeakReducerType.setPrices:
            return {
                ...state,
                prices: action.payload
            }
        case TkDeakReducerType.setShipmentCost:
            return {
                ...state,
                shipmentCost: action.payload
            }
        case TkDeakReducerType.setIsMakingOrder:
            return {
                ...state,
                isMakingOrder: action.payload
            }
        case TkDeakReducerType.clearProcess:
            return {
                ...state,
                shipmentCost: "0",
                isLoadingList: false,
                isMakingOrder: false
            }
        default:
            return state
    }
}

export default TkDeakReducer