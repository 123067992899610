import './style.scss'
import React, {CSSProperties, FC, memo, PropsWithChildren, useEffect, useMemo, useRef, useState} from "react";
import {useTkBottomSheetContext} from "../../../context/TkContext";

const TkBottomSheet:FC<PropsWithChildren<any>> = ({children}) => {
    const bottomSheetRef = useRef(null);
    const bottomSheetScrollRef = useRef<HTMLDivElement>();
    const {setShow, isShow} = useTkBottomSheetContext()

    const minHeight = 70;
    const maxHeight = window.innerHeight * 0.8;
    const [height, setHeight] = useState(minHeight);


    useEffect(() => {
        const hammer = new Hammer(bottomSheetRef.current);
        hammer.get('pan').set({direction: Hammer.DIRECTION_VERTICAL});

        const listening = () => {
            setShow(is => !is)
        };

        hammer.on('panup', listening);
        hammer.on('pandown', listening);

        return () => {
            hammer.off('panup', listening);
            hammer.off('pandown', listening);
            hammer.destroy()
        };
    }, [setShow]);

    const styleScroll = useMemo((): CSSProperties => {
        if (isShow) return {overflowY: 'auto', scrollBehavior: 'smooth', paddingBottom: 50}
        return {overflowY: 'hidden'}
    }, [isShow]);

    useEffect(() => {
        bottomSheetScrollRef.current.scrollTop = 0;
        setHeight(isShow ? maxHeight : minHeight)
    }, [isShow]);


    return <div className='TkBottomSheet__container'
                style={{height: `${height}px`}}>
        <div ref={bottomSheetRef}
             style={{
                 height: 14,
                 width: '100%',
             }} className='d-flex a-i-c j-c-c'
             onClick={() => setShow(is => !is)}>
            <div className='TkBottomSheet__toothpick'/>
        </div>
        <div style={{...styleScroll, width: '100%', paddingRight: 10}} ref={bottomSheetScrollRef}>
            {children}
        </div>
    </div>
}

export default memo(TkBottomSheet)