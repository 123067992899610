import React, {memo} from "react";
import './style.scss'
import {Link} from "react-router-dom";
import TkSvgIcon from "../../particles/TkSvgIcon";
import {useTkMedia, useTkProduct} from "../../../context/TkContext";
import {tk_route_division} from "../../../views/TkSearchView";
import TkSkeleton from "../../particles/TkSkeleton";

const TkDivisionsTop: React.FC = () => {
    const {isMediaXs, isMediaSm} = useTkMedia();
    const {state: {divisions}} = useTkProduct();

    if (isMediaXs || isMediaSm) return <></>;


    if (!divisions || divisions.length === 0) {

        const skeletons = Array(13).fill(null)

        return <nav className='TkDivisionsTop'>
            <ul className='TkDivisionsTop__menu'>
                {skeletons.map((_, idx) => (<li key={`brb_skl_${idx}`}>
                    <a href='#'>
                        <TkSkeleton width='2rem' height='2rem' style={{display: 'inline-block', marginBottom: 0, borderRadius: '20%', animationDelay: `.${idx}s`}}/>
                        <span className='t-a-c'><TkSkeleton width={60} style={{display: 'inline-block', marginBottom: 0, borderRadius: '10%', animationDelay: `.${idx + 1}s`}}/></span>
                    </a>
                </li>))}
            </ul>
        </nav>
    }

    return <nav className='TkDivisionsTop'>
        <ul className='TkDivisionsTop__menu'>
            {divisions.map((d, idx) => {
                return <li key={idx}>
                    <Link to={tk_route_division(d)}>
                        <TkSvgIcon icon={d.icon}/>
                        <span className='t-a-c'>{d.name}</span>
                    </Link>
                </li>
            })}
        </ul>
    </nav>
};

export default memo(TkDivisionsTop);
