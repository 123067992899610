import React from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";

export const tk_route_experts = '/experts';

const TkExpertsView: React.FC = () => {
    return <>
        <TkHeadSEO title='Experts | Plenobras'
                   description='Saiba como funciona o Plenobras Experts e participe'/>

        <TkHeader/>

        <TkDivisionsTop/>
        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_experts,
                    label: 'Experts'
                }
            ]}/>

            <h1>Plenobras Experts</h1>
            <div className="row">
                <div className="col-sm-8 offset-sm-2 col-md-6 offset-sm-3">
                    <p className='t-a-c'>O Plenobras Experts nasceu com o propósito de promover o conhecimento técnico
                        através da conexão entre profissionais e criar soluções que aumentam a produtividade,
                        conhecimento e descoberta.</p>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <p className='t-a-c'>Lançamento em Breve!</p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>

        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default TkExpertsView
