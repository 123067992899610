const _xs = '(max-width: 576px)';
const _sm = '(max-width: 768px)';
const _md = '(max-width: 992px)';
const _lg = '(min-width: 993px)';
const _xl = '(min-width: 1201px)';
const matchMedia = (media: string): boolean => window.matchMedia(media).matches;

const isMediaXs = (): boolean => matchMedia(_xs);
const isMediaSm = (): boolean => matchMedia(_sm);
const isMediaMd = (): boolean => matchMedia(_md);
const isMediaLg = (): boolean => matchMedia(_lg);
const isMediaXl = (): boolean => matchMedia(_xl);
const isLandscape = (): boolean => matchMedia('orientation: landscape');

export {
    matchMedia,
    isMediaXs,
    isMediaSm,
    isMediaMd,
    isMediaLg,
    isMediaXl,
    isLandscape
}
