import React, {memo, useEffect} from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {isMediaXs} from "../../utils/screen-utils";
import TkLazyImage from "../../components/particles/TkLazyImage";
import TkLazyImageSlider from "../../components/particles/TkLazyImageSlider";

export const tk_route_withdraw_policy = '/retirada';
const mobileSlides = [
  "https://teky.s3.sa-east-1.amazonaws.com/banners/card-retira-mobile1.webp",
  "https://teky.s3.sa-east-1.amazonaws.com/banners/card-retira-mobile2.webp",
  "https://teky.s3.sa-east-1.amazonaws.com/banners/card-retira-mobile3.webp"
]

export const tkAddressWithdraw  = () => {
  return 'R.Voluntários da Pátria, 2035 - Floresta, Porto Alegre - RS, 90230-011'
}
export const tkLinkMapWithdraw  = () => {
  return 'https://www.google.com.br/maps/place/Plenobras/@-30.016951,-51.2134331,17.75z/data=!4m6!3m5!1s0x95197990e605feab:0xc33ae6a92fd1d5f0!8m2!3d-30.0169048!4d-51.2118759!16s%2Fg%2F1s04brwgl?entry=ttu'
}
const TkWithdrawPolicyView: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <>
    <TkHeadSEO title='Política de Retirada | Plenobras'
               description='Compre no site e retire na loja'/>

    <TkHeader/>

    <TkDivisionsTop/>

    <div className="container m-t-1rem">
      <TkBreadcrumb list={[{
        url: tk_route_withdraw_policy,
        label: 'Política de Retirada'
      }]}/>

      <p><strong>Compre no site e retire na loja!</strong></p>
      <p>Rapidez, economia e conveniência para você.</p>
      <br/>
      {/* Inserir video cliente retira */}
      <ul>
        <strong>Perguntas frequentes sobre a opção de retirada em loja:</strong>
        <ul>
          <li><strong>1 . Qual o prazo mínimo para retirada da compra?</strong> A Partir de 24h úteis.</li>
          <ul><li><strong>1.1.</strong> O prazo mínimo é contado a partir de 12h úteis entre os horários de funcionamento da loja; das 7:45 às 
          18h de segunda-feira a sexta-feira.</li></ul>
        </ul>
        <br/>
        <ul>
          <li><strong>2 .</strong> Todos os materiais disponíveis no site poderão ser retirados em loja.</li>
          <ul><li><strong>2.1.</strong> As mercadorias do parceiro Plenobras devem ser retiradas no endereço: R.Voluntários da Pátria, 2035 - 
          Floresta, Porto Alegre - RS, 90230-011</li>
          <li><strong>2.2.</strong> Para verificação da disponibilidade de antecipação da retirada entre em contato conosco pelos nossos canais de atendimento.'</li></ul>
        </ul>
      </ul>
      <br/>

      <div className="m-t-20px">
        {isMediaXs() ?
          <TkLazyImageSlider images={mobileSlides}/> :
          <TkLazyImage
            src="https://teky.s3.sa-east-1.amazonaws.com/banners/cards-retira-desktop.webp"
            alt="Banner Mobile Cliente Retira"
            className={`img-responsive`}
          />
        }
      </div>
    </div>

    <TkNewsLetter/>
    <TkFooter/>
  </>
};

export default memo(TkWithdrawPolicyView)
