import {City, State} from "./localization";

export enum AccountType {
    standard = 'standard',
    pro = 'pro',
    business = 'business'
}

export enum UserRole {
    PLENOBRAS_SELLER_MAKE_ORDER = 'PLENOBRAS_SELLER_MAKE_ORDER',
    PLENOBRAS_SELLER_LIST_MANAGER = 'PLENOBRAS_SELLER_LIST_MANAGER',
}

export interface Address {
    _id?: string | null
    stateId?: string | null
    cityId?: string | null
    state?: State | null
    city?: City | null
    neighborhoodName?: string | null
    location?: string | null
    locationNumber?: string | null
    locationNotes?: string | null
    postalCode?: string | null
    isDefault?: boolean | null
    updatedAt?: Date
    cei?: string
}

export interface User {
    _id?: string
    fullName?: string
    email?: string
    birthDate?: string
    document?: string
    pix?: string
    password?: string
    phone?: string,
    cellphone?: string
    userType?: string
    profession?: string
    occupationArea?: string
    addresses?: Address[]
    defaultAddress?: Address
    defaultCompany?: Company
    receiveNews?: boolean
    companies?: Company[]
    profile?: ITkUserProfileModel
}

export interface UserAuth {
    error?: boolean
    errorMessages?: any
    user?: User | null
    token?: string | null
}

export interface Company {
    document?: string
    socialReason?: string
    emailInvoice?: string
    taxContributingType?: string
    taxContributingCode?: string
    defaultAddress?: Address
    addresses?: Address[]
    isDefault?: boolean
    createdAt?: string
    updatedAt?: string
}

export interface ITkProfileRoleModel {
    name?: string
    key?: string
    description?: string
}

export interface ITkUserProfileModel {
    name?: string
    key?: string
    description?: string
    roles?: ITkProfileRoleModel[]
}