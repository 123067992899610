import React from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";

export const tk_route_price_policy = '/politica-de-precos';

const TkPricePolicyView: React.FC = () => {
    return <>
        <TkHeadSEO title='Política de Preços | Plenobras'
                   description='Saiba como funciona nossa política de preços'/>

        <TkHeader/>

        <TkDivisionsTop/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_price_policy,
                    label: 'Política de Preços'
                }
            ]}/>

            <h1>Política de Preços</h1>
        </div>

        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default TkPricePolicyView
