import React, {useEffect, useState} from 'react';
import {useTkDeakContext} from "../../context/TkContext";
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import {formatDocument, interactiveFormatDocument, isCnpj, isCpf, onlyNumbers} from "../../utils/string-utils";
import {animated as a, useTrail} from "react-spring";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import TkReticenceLoading from "../../components/particles/TkReticenceLoading";


const _stepCustomerLookup: React.FC<{
    next: () => void;
    isInOrOut: boolean;
}> = ({next, isInOrOut = true}) => {
    const {searchCustomerPlenobrasSellerOrder, setCustomer, customer} = useTkDeakContext();
    const {
        value: document,
        setValue: setDocument,
    } = useSimpleInput("");
    const [isSearching, setSearching] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const config = {mass: 5, tension: 2000, friction: 200};
    const trail = useTrail(errors.length, {
        config,
        opacity: errors.length > 0 ? 1 : 0,
        from: {opacity: 0},
    });

    useEffect(() => {
        if (customer) setDocument(formatDocument(customer.document))
    }, []);

    const deakLookup = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrors([])

        if (!isCpf(document) && !isCnpj(document)) {
            setErrors(["CPF ou CNPJ inválido"]);
            return;
        }

        setSearching(true);
        try {
            setCustomer({document})
            const result = await searchCustomerPlenobrasSellerOrder(document);
            setSearching(false)

            if (result) setCustomer({
                ...result,
                document: formatDocument(result.document)
            })
        } catch (e) {
            console.error('Falha na consulta de cliente')
        } finally {
            setSearching(false)
            next();
        }
    }


    const checkDeakData = () => {
        return <form onSubmit={deakLookup} className='TkMakeDeakOrderView__form'>
            <div className='TkMakeDeakOrderView__lookup-input'>
                <input type="text"
                       className='form-control form-control-lg'
                       disabled={isSearching}
                       value={document}
                       onChange={(e) => {
                           setDocument(interactiveFormatDocument(e.target.value))
                       }}
                       maxLength={18}
                       placeholder='Informe CPF ou CNPJ'/>
                <button className='btn btn-primary btn-lg'
                        disabled={isSearching}
                        type='submit'>{isSearching ? <TkSvgIcon icon='sync-solid' className='rotate-1-seg' style={{
                    width: '1em',
                    height: '1em',
                }} /> : 'Avançar'}</button>
            </div>
            {isSearching && <small>{<TkReticenceLoading label={`Consultando ${onlyNumbers(document).length <= 11 ? 'CPF' : 'CNPJ'}`}/>}</small>}


            {trail.map((props, index) => (
                <a.div key={index} className="alert alert-danger" style={props}>
                    {errors[index]}
                </a.div>
            ))}
        </form>
    }

    return checkDeakData();
};

export default _stepCustomerLookup;