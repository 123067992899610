import React, {memo, useEffect} from "react";
import {Link} from "react-router-dom";
import './style.scss';
import {tk_route_root} from "../../../views/TkHomeView";
import {Breadcrumb} from "../../../context/TkBreadCrumbContext";
import {useTkBreadcrumb} from "../../../context/TkContext";
import TkSkeleton from "../TkSkeleton";

const TkBreadcrumb: React.FC<{
    list: Breadcrumb[]
    showSkeleton?: boolean
    className?: string
}> = ({list, showSkeleton, className}) => {
    const {setBreadcrumb} = useTkBreadcrumb();

    const home = [{
        url: tk_route_root,
        label: 'Home'
    }];

    useEffect(() => {
        if (list && list.length > 0) setBreadcrumb(home.concat(list))
    }, []);

    if (showSkeleton) {
        const skeletons = Array(4).fill(null)
        return <nav className='TkBreadcrumb'>
            <ol>
                {skeletons.map((_, idx) => (<li key={`brcb_sklt_${idx}`}>
                    <a href='#'>
                        <TkSkeleton width={60} style={{display: 'inline-block', marginBottom: 0}}/>
                    </a>
                </li>))}
            </ol>
        </nav>
    }

    return <nav className={`TkBreadcrumb ${className  || ''}`}>
        <ol>
            {home.concat(list).map((b, idx) => (
                <li key={idx}>
                    <Link to={b.url}>{b.label}</Link>
                </li>
            ))}
        </ol>
    </nav>;
};

export default memo(TkBreadcrumb)
