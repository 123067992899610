import {useCallback, useState} from "react";
import {isLandscape, isMediaLg, isMediaMd, isMediaSm, isMediaXl, isMediaXs} from "../utils/screen-utils";
import useEventListener from "../hooks/UseEventListener";

export interface TkMediaContextType {
    isMediaXs: boolean;
    isMediaSm: boolean;
    isMediaMd: boolean;
    isMediaLg: boolean;
    isMediaXl: boolean;
    isLandscape: boolean;
    isMobile: boolean;
}

const TkMediaContext = (): TkMediaContextType => {
    const [state, setState] = useState<TkMediaContextType>({
        isMediaXs: isMediaXs(),
        isMediaSm: isMediaSm(),
        isMediaMd: isMediaMd(),
        isMediaLg: isMediaLg(),
        isMediaXl: isMediaXl(),
        isLandscape: isLandscape(),
        isMobile: isMediaXs() || isMediaSm()
    });

    const handler = useCallback(() => {
        setState({
            isMediaXs: isMediaXs(),
            isMediaSm: isMediaSm(),
            isMediaMd: isMediaMd(),
            isMediaLg: isMediaLg(),
            isMediaXl: isMediaXl(),
            isLandscape: isLandscape(),
            isMobile: isMediaXs() || isMediaSm()
        });
    }, [setState]);

    useEventListener('resize', handler);

    return state;
};


export default TkMediaContext;
