import './style.scss'
import React, {FC, memo} from "react";
import TkSvgIcon from "../TkSvgIcon";
import {useTkDrawer, useTkWhatsAppBalloon} from "../../../context/TkContext";
import TkDrawer from "../TkDrawer";
import {tk_route_root} from "../../../views/TkHomeView";
import TkLazyImage from "../TkLazyImage";
import PlenoLogo from "../../../assets/images/logo-loja-plenobras-colored.svg";
import {Link} from "react-router-dom";

const TkSellerHeader: FC = () => {
    const {setIsShow} = useTkDrawer();
    const {setShowIcon} = useTkWhatsAppBalloon()

    return <>
        <header className='TkSellerHeader'>
            <span title='Menu de áreas' onClick={() => {
                setIsShow(true)
                setShowIcon(false)
            }} className='Header__menu-bars'>
              <TkSvgIcon icon='bars-solid'/>
            </span>
            <Link to={tk_route_root}
                  className='TkSellerHeader__logo-container'
                  title="Link para Home Page">
                <TkLazyImage
                    src={PlenoLogo}
                    className="TkSellerHeader__logo"
                    alt="Plenobras"
                />
            </Link>
        </header>
        <TkDrawer/>
    </>
}

export default memo(TkSellerHeader);