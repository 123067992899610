import './style.scss'
import {isBlank} from "../../utils/string-utils";
import React, {FC, RefObject, useCallback, useEffect, useRef, useState} from "react";
import {useTkMedia, useTkProduct, useTkUser, useTkWhatsAppBalloon} from "../../context/TkContext";
import TkModalEditList, {ITkModalEditList} from "../../components/list/TkModalEditList";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkSellerHeader from "../../components/particles/TkSellerHeader";
import _ListInfo from "./_listInfo";
import TkBottomSheet from "../../components/particles/TkBottomSheet";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import _ProductFilters from "./_productFilters";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {List} from "../../models/list";
import _ListContext from "./_listContext";
import {DateFormats, formatDateFromString, formatRelativeDate} from "../../utils/date-utils";
import {formatMoney} from "../../utils/number-utils";
import TkModal, {TkModalActions, TkModalBody} from "../../components/particles/TkModal";
import _PickupProductCard from "./_pickupProductCard";
import TkSkeleton from "../../components/particles/TkSkeleton";
import {useHistory, useLocation} from "react-router-dom";
import {ITkProductModel} from "../../models/product";
import {toast} from "react-toastify";
import TkPaginator from "../../components/particles/TkPaginator";

const DEFAULT_ROUTE = '/seller/lists-manager' as const

export const tk_route_seller_list_manager_view = (listId?: string) => {
    const params = new URLSearchParams()
    if (!isBlank(listId)) params.set('listId', listId)

    if (Array.from(params.keys()).length > 0) return `${DEFAULT_ROUTE}?${params.toString()}`

    return DEFAULT_ROUTE
}

const ListButtonActionButtons:FC<{
    setShowPickUpList: (show: boolean) => void
    modalListManagerRef: RefObject<ITkModalEditList>
}> = ({modalListManagerRef, setShowPickUpList}) => {
    return <>
        <button type='button'
                onClick={() => setShowPickUpList(true)}
                className='TkSellerListManagerView__pickup-list'>Selecionar outra
            lista <TkSvgIcon icon='list-alt-regular'/></button>
        <button type='button'
                onClick={() => modalListManagerRef.current.show()}
                className='TkSellerListManagerView__add-list m-t-10px'>Criar nova lista
            lista <TkSvgIcon icon='plus-solid'/></button>
    </>
}

const TkSellerListsManagerView: FC = () => {
    const history = useHistory()
    const {state, search, pathname} = useLocation()
    const params = new URLSearchParams(search)
    const {setShowIcon, isShowIcon} = useTkWhatsAppBalloon()
    const {getLists, getList, updateList, removeFromList} = useTkUser()
    const {productSearch} = useTkProduct()
    const {isMediaLg, isMediaXl} = useTkMedia()
    const [isShowPickUpList, setShowPickUpList] = React.useState(false)
    const [lists, setLists] = useState<List[]>([])
    const [list, setList] = useState<List>(null)
    const [isLoadingLists, setLoadingLists] = useState(false)
    const [isLoadingList, setLoadingList] = useState(false)
    const [_, setUpdatingList] = useState(false)
    const [productsUpdating, setProductsUpdating] = useState<Set<string>>(new Set([]))
    const modalListManagerRef = useRef<ITkModalEditList>();
    const products:ITkProductModel[] = state?.products || []
    const total:number = state?.total || 0
    const [isLoadingProducts, setLoadingProducts] = useState(false)
    const isLargeScreen = isMediaLg || isMediaXl

    useEffect(() => setShowIcon(false), [isShowIcon, setShowIcon]);

    const loadProducts = useCallback(async () => {
        setLoadingProducts(true)

        try {
            const result = await productSearch({
                term: params.get('term') || '',
                showAvailableStock: 'true',
                from: parseInt(params.get('from') || '0')
            })
            if (result.total > 0) history.replace(`${pathname}?${params.toString()}`, {total: result.total, products: result.items})
        } catch (e) {
            console.error(e)
        } finally {
            setLoadingProducts(false)
        }
    }, [search, pathname])


    useEffect(() => {
        loadProducts()
    }, [loadProducts]);


    useEffect(() => {
        if(isShowPickUpList) loadLists()
    }, [isShowPickUpList]);

    const loadLists = useCallback(() => {
        setLoadingLists(true)

        getLists().then((result) => {
            if (result.success) setLists(result.data)
        }).catch((e) => {
            console.error(e)
        }).finally(() => setLoadingLists(false))
    }, [])

    const loadList = useCallback((listId: string) => {
        setLoadingList(true)

        getList(listId).then((result) => {
            if (result.success) setList(result.data)
        }).catch((e) => {
            console.error(e)
        }).finally(() => setLoadingList(false))
    }, [])

    const isProductInList = useCallback((productId: string) => {
        return list?.items.some(item => item.product._id === productId)
    }, [list])

    const productQuantityInList = useCallback((productId: string) => {
        const item = list?.items.find(item => item.product._id === productId)
        return item ? item.quantity : 0
    }, [list])

    const updateProductInList = useCallback((productId: string, quantity: number) => {
        if (!list) {
            toast.info('Não há uma lista selecionada!')
            return
        }

        setUpdatingList(true)
        setProductsUpdating(prev => new Set([...Array.from(prev), productId]));
        updateList(list._id, productId, quantity, false)
            .then(result => {
                if (result.success) {
                    setList(result.data)
                    toast.info(`Produto atualizado com sucesso`)
                }
            })
            .finally(() => {
                setUpdatingList(false)
                setProductsUpdating(prev => {
                    return new Set(Array.from(prev).filter(id => id !== productId))
                })
            })
    }, [list])

    const removeProductFromList = useCallback((productId: string) => {

        if (!list) {
            toast.info('Não há uma lista selecionada!')
            return
        }

        setUpdatingList(true)

        removeFromList(list._id, productId)
            .then(result => {
                if (result.success) {
                    setList(result.data)
                    toast.info(`Produto removido da lista com sucesso`)
                }
            })
            .finally(() => setUpdatingList(false))
    }, [list])

    return <_ListContext.Provider value={{
        lists,
        list,
        setLists,
        setList,
        loadLists,
        loadList,
        isLoadingLists,
        isLoadingList,
        isUpdatingProductInList: (productId: string) => productsUpdating.has(productId),
        isProductInList,
        productQuantityInList,
        updateProductInList,
        removeProductFromList
    }}>
        <>
            <TkHeadSEO title="Gestão de listas"/>

            <TkSellerHeader/>

            <div className='container'>
                <TkBreadcrumb
                    className='m-t-15px'
                    list={[
                        {label: 'Gestão de listas', url: DEFAULT_ROUTE}
                    ]}/>

                <div className='row'>
                    <div className='col-sm-12 col-md-4 col-lg-3 col-xl-2'>
                        <_ProductFilters/>
                        {!isLargeScreen ?
                            <div className='m-t-20px m-b-20px'>
                                <ListButtonActionButtons setShowPickUpList={setShowPickUpList}
                                                         modalListManagerRef={modalListManagerRef}/>
                            </div>
                        : null
                        }
                    </div>

                    <div className='col-sm-12 col-md-12 col-lg-6 col-xl-8 d-flex flex-d-c'>
                        {isLoadingProducts && products.length === 0 ?
                            null
                            :
                            products?.map(product => (
                                <_PickupProductCard key={product._id} product={product}/>
                            ))
                        }

                        <div className='m-t-40px'>
                            <TkPaginator goToUrl={history.push} totalItems={total}/>
                        </div>
                    </div>

                    {isLargeScreen ? <div className='col-sm-12 col-md-3 col-lg-3 col-xl-2'>

                            <ListButtonActionButtons setShowPickUpList={setShowPickUpList}
                                                     modalListManagerRef={modalListManagerRef}/>
                            <div className='TkProductDetailView__sticky'>
                                <_ListInfo className='m-t-10px'/>
                            </div>
                        </div>
                        :
                        null
                    }

                </div>
            </div>

            {!isLargeScreen ?
                <TkBottomSheet>
                    <_ListInfo className='m-t-10px'
                               mode='bottom-sheet'/>
                </TkBottomSheet>
                :
                null
            }

            <TkModal show={isShowPickUpList} title='Seleção de lista' close={() => setShowPickUpList(false)}>
                <TkModalBody>
                    {isLoadingLists && lists.length === 0 ?
                        Array.from({length: 3}).map((_, idx) => (
                            <TkSkeleton key={idx} style={{
                                width: '100%',
                                height: 106,
                                marginBottom: 5
                            }}/>
                        ))
                        :
                        <div className='TkSellerListManagerView__lists-container'>
                            <ul className='TkSellerListManagerView__lists'>
                                {lists.map((list) => (
                                    <li key={list._id} onClick={() => {
                                        setShowPickUpList(false)
                                        setTimeout(() => history.push(tk_route_seller_list_manager_view(list._id)), 300)
                                    }}>
                                        <h1 className='ellipsis'>{list.name}</h1>
                                        <small
                                            title={formatDateFromString(list.createdAt, DateFormats.ddMMyyyyHHmm)}>{formatRelativeDate(list.createdAt)}</small>
                                        <div className='d-flex a-i-c j-c-sb' style={{
                                            gridArea: 'value'
                                        }}>
                                            <span>Produtos<br/>{list.totalQuantity}</span>
                                            <span
                                                className='t-a-r f-w-b'>Total<br/>{formatMoney(list.totalValue)}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }
                </TkModalBody>

                <TkModalActions>
                    <button type='button'
                            className='btn btn-secondary'
                            onClick={() => setShowPickUpList(false)}>Fechar
                    </button>
                </TkModalActions>
            </TkModal>

            <TkModalEditList updateListState={(listCreated) => {
                modalListManagerRef.current.close()

                const currentListId = list._id

                if (listCreated._id !== currentListId) {
                    params.set('listId', listCreated._id)
                    history.push(`${DEFAULT_ROUTE}?${params.toString()}`)

                    toast.info('Lista criada com sucesso')
                }
            }} ref={modalListManagerRef}/>
        </>
    </_ListContext.Provider>
}


export default TkSellerListsManagerView