import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import "./style.scss";
import {useTkMedia} from "../../context/TkContext";
import SmallScreen from "./_smallScreen";
import MediumLargeScreen from "./_mediumLargeScreen";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkHeader from "../../components/particles/TkHeader";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";

export const tk_route_root = "/";

const TkHomeView: React.FC = () => {
  const { isMediaXs } = useTkMedia();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <TkHeadSEO
        title="Plenobras"
      />
      <TkHeader />

      {isMediaXs ? <SmallScreen /> : <MediumLargeScreen />}
      <TkNewsLetter />
      <TkFooter />
    </>
  );
};

export default TkHomeView;
