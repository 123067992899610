import React, {lazy, Suspense, useEffect, useState} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import {TkLogoBuilder} from "../../components/particles/TkLogoVector";
import './style.scss'
import {useLocation} from "react-router-dom";

const LoginForm = lazy(() => import('./_loginForm'))
const SuccessConfirmation = lazy(() => import('./_successConfirmation'))

export const tk_route_sign_in = '/entrar';

const TkSignInView: React.FC = () => {
    const location = useLocation()
    const [isShowSuccess, setShowSuccess] = useState(false)

    useEffect(() => {
        const p = new URLSearchParams(location.search)
        setShowSuccess(p.has('success-confirmation'))
    }, [location, setShowSuccess])


    return <div className='TkSignInView'>
        <TkHeadSEO title='Acessar a Plenobras'/>

        <div className="container m-t-1rem">
            <div className='t-a-c'>
                <TkLogoBuilder/>
            </div>
        </div>

        <Suspense fallback={<div/>}>
            {isShowSuccess ?
                <SuccessConfirmation/>
                :
                <LoginForm/>
            }
        </Suspense>
    </div>
};

export default TkSignInView;