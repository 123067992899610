import React, {memo} from "react";
import './style.scss';
import {Link} from "react-router-dom";
import {tk_route_root} from "../../../views/TkHomeView";

const TkCopyright: React.FC = () => {
    return <div className='TkCopyright'>
        Copyright © <Link to={tk_route_root} title='Ir para o site'>Plenobras</Link> {new Date().getFullYear()}.
    </div>
};

export default memo(TkCopyright);
