import React, {useCallback, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkHeader from "../../components/particles/TkHeader";
import TkFooter from "../../components/particles/TkFooter";
import {useTkMedia} from "../../context/TkContext";
import "./style.scss";
import {ReactComponent as Logo} from "../../assets/images/logo-loja-plenobras-colored.svg";
import {tk_route_sign_up_pro} from "../TkSignUpView";

export const tk_route_pro = "/pro";

const TkProView: React.FC = () => {
  const { isMediaXs, isMediaSm, isMediaMd, isMediaLg } = useTkMedia();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getBestImage = useCallback(() => {
    if (isMediaXs) return "sd";
    else if (isMediaSm || isMediaMd) return "mq";
    else if (isMediaLg) return "hq";
    else return "maxres";
  }, [isMediaXs, isMediaSm, isMediaMd, isMediaLg]);


  return (
    <>
      <TkHeadSEO
        title="Pro"
        description="Ferramenta de trabalho para profissionais técnicos. Agilidade e economia para seus clientes. Indique a Plenobras para seus clientes e ganhe bonificações!"
      />

      <TkHeader />

      <section className="TkProView container">
        <h1 className="TkProView__logo">
          <Logo className="TkProView__logo--svg" />
        </h1>

        <p className="TkProView__text-1">
          Indique a Plenobras para seus clientes!
        </p>

        <div className="row m-b-1rem">
          <div className="col-12 col-md-4">
            <Link
              to={tk_route_sign_up_pro}
              className="TkProView__link button-3"
            >
              Comece agora
            </Link>
          </div>
          <div className="col-12 col-md-8 TkProView__text-2">
            <p>A ferramenta de trabalho para os profissionais técnicos.</p>
            Agilidade e economia para seus clientes.
          </div>
        </div>

        <div className="TkProView__text-margin">
          <p>Como funciona:</p>
          <p>
            1. Especifique o produto, comparando marcas e preços de diferentes
            fornecedores.
          </p>
          <p>
            2. Adicione o produto à sua lista, clicando em&nbsp;
            <button className="button-3 d-inline">PRO</button>
          </p>
          <p>3. Envie o link com a lista criada para seus clientes.</p>
          <p>
            4. Gestão e organização das listas de orçamento em um dashboard PRO
            exclusivo.
          </p>
          <p>PRONTO! Para começar, é fazer o cadastro no site!</p>

          <div>
            <Link
              to={tk_route_sign_up_pro}
              className="TkProView__link button-3"
            >
              Comece agora
            </Link>
          </div>
        </div>
      </section>
      <TkNewsLetter />
      <TkFooter />
    </>
  );
};

export default TkProView;
