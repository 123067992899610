import React, {FC, memo, useCallback, useState} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import {TkLogoBuilder} from "../../components/particles/TkLogoVector";
import TkDivider from "../../components/particles/TkDivider";
import {tk_var_teky_address1, tk_var_teky_document, tk_var_teky_name} from "../TkBeInTouchView";
import {formatDocument} from "../../utils/string-utils";
import {animated as a, useTrail} from "react-spring";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {getIcon, getTitle} from "./_utils";
import {Link, useHistory, useLocation} from "react-router-dom";
import {AccountType} from "../../models/user";
import {tk_route_exchanges_and_devolution} from "../TkExchangesAndDevolutionView";
import {tk_route_privacy_policy} from "../TkPrivacyPolicyView";
import DeviceStorage from "../../utils/storage-utils";
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import {useTkAppErrors, useTkMedia, useTkUser} from "../../context/TkContext";
import {tk_route_forget_pass} from "../TkForgotPasswordView";

const DescContent: FC<{
    isCreate: boolean
    type: AccountType
}> = ({isCreate, type}) => {

    const {isMediaXs, isMediaSm} = useTkMedia()

    if (isCreate) {
        return <small>Preencha os dados {isMediaXs || isMediaSm ? 'abaixo' : 'ao lado'} para criar sua conta {getTitle(type)}.</small>
    }

    return <>
        <small>Parece que você já tem uma conta existente da Plenobras associada ao seu e-mail.</small>
        <br/>
        <br/>
        <small>Entre e decida se deseja mantê-la separado ou convertê-la em um outro tipo de conta.</small>
    </>
}

const Form: FC<{
    type: AccountType
}> = ({type}) => {
    const history = useHistory()
    const {search, pathname} = useLocation()
    const p = new URLSearchParams(search)
    const email = DeviceStorage.getEmailSignup
    const {signUp, signIn} = useTkUser()
    const {goToPageError} = useTkAppErrors()
    const [isDoing, setDoing] = useState(false)
    const [errors, setErrors] = useState([])
    const {bind: bindName, value: nameValue} = useSimpleInput('');
    const {bind: bindEmail, value: emailValue} = useSimpleInput(email || '');
    const {bind: bindPass, value: passValue} = useSimpleInput('');
    const isCreate = p.get('estagio') === 'criar';
    const accountType = p.get('tipo');

    const config = {mass: 5, tension: 2000, friction: 200}
    const trail = useTrail(errors.length, {
        config,
        opacity: 1,
        from: { opacity: 0 },
    });

    const doSignUp = useCallback(async () => {
        setDoing(true)
        setErrors([])
        try {
            const {errorMessages} = await signUp(nameValue, emailValue, passValue, accountType, !isCreate);

            if (errorMessages) {
                setErrors(errorMessages.map((em: any) => em.message))
            } else {
                DeviceStorage.destroyEmailSignup()

                await signIn(emailValue, passValue);

                if (accountType === AccountType.pro || accountType === AccountType.business) {
                    p.set('estagio', 'completar-dados')
                } else {
                    p.set('estagio', isCreate ? 'criacao-sucesso' : 'conversao-sucesso')
                }

                history.replace(`${pathname}?${p.toString()}`)
            }
        } catch (e) {
            goToPageError(e)
        } finally {
            setDoing(false)
        }
    }, [nameValue, emailValue, passValue, setDoing, setErrors, goToPageError])

    const doSignIn = useCallback(async () => {
        setDoing(true)
        setErrors([])
        try {
            const {errorMessages} = await signIn(emailValue, passValue);

            if (errorMessages) {
                setErrors(errorMessages.map((em: any) => em.message))
            } else {
                p.set('estagio', 'converter-ou-mudar-email')
                history.replace(`${pathname}?${p.toString()}`)
            }

        } catch (e) {
            goToPageError(e)
        } finally {
            setDoing(false)
        }
    }, [emailValue, passValue, setDoing, setErrors, signIn, goToPageError])


    return <>
        <h2>{isCreate ? 'Registre-se' : 'Identifique-se'} para começar</h2>


        <label htmlFor="signUpInEmail" className='m-t-20px'>E-mail <span style={{
            color: 'red'
        }}>*</span></label>
        <input type="email"
               id='signUpInEmail'
               autoComplete='off'
               disabled={isDoing}
               className='form-control form-control-sm'
               placeholder='Informe o e-mail' {...bindEmail}/>

        {isCreate && <>
            <label htmlFor="signUpInName" className='m-t-20px'>Nome completo <span style={{
                color: 'red'
            }}>*</span></label>
            <input type="text"
                   id='signUpInName'
                   disabled={isDoing}
                   className='form-control form-control-sm'
                   placeholder='Informe o nome completo' {...bindName}/>

        </>}

        <label htmlFor="signUpInPass" className='m-t-20px'>Senha <span style={{
            color: 'red'
        }}>*</span></label>
        <input type="password"
               id='signUpInPass'
               autoComplete='off'
               disabled={isDoing}
               className='form-control form-control-sm'
               placeholder='Informe sua senha' {...bindPass}/>

        {trail.map((props, index) => (
            <a.div
                key={index}
                className='alert alert-sm alert-danger m-t-20px'
                style={props}>
                {errors[index]}
            </a.div>
        ))}

        {!isCreate && <div className="TkSignInView__forget" style={{marginTop: 10}}>
            <Link to={tk_route_forget_pass} title='Esqueci a senha'>Esqueci a senha</Link>
        </div>}

        <br/>
        <small className='m-t-20px'>Ao continuar, você concorda com as <Link to={tk_route_exchanges_and_devolution}>Condições de Uso</Link> e <Link to={tk_route_privacy_policy}>Política de Privacidade</Link> da Plenobras.</small>
        <button type='button'
                onClick={isCreate ? doSignUp : doSignIn}
                disabled={isDoing}
                className={`TkSignUpView__plan-button TkSignUpView__plan-button-${type}`}>
            <TkSvgIcon icon={isDoing ? 'sync-solid' : getIcon(type)} className={`m-r-1em ${isDoing ? 'rotate-1-seg' : ''}`}/>
            {isCreate ? 'Criar' : 'Acessar'} conta
        </button>
    </>
}

const _SignInOrCreateAccount:FC = () => {
    const {search} = useLocation()
    const p = new URLSearchParams(search)
    const isCreate = p.get('estagio') === 'criar';
    const type: AccountType = AccountType[p.get('tipo') as keyof typeof AccountType]

    const config = {mass: 5, tension: 2000, friction: 200}
    const trail = useTrail(2, {
        config,
        from: {
            opacity: 0,
            transform: `translate3d(0,40px,0)`
        },
        to: {
            opacity: 1,
            transform: 'translate3d(0,0,0)'
        }
    });

    return <>
        <TkHeadSEO title='Criar ou Converter conta | Plenobras' description='Deseja criar uma nova conta ou converter a atual'/>

        <div className="container m-t-1rem t-a-c">
            <TkLogoBuilder/>

            <div className='TkSignUpView__container_sign m-t-40px'>
                {trail.map((props, index) => {
                    const isLeft = index === 0

                    return <a.article style={props}
                                      key={index}
                                      className={`TkSignUpView__card-sign ${isLeft ? '' : 'TkSignUpView__card-sign-form'}`}>
                        {isLeft ?
                            <DescContent isCreate={isCreate} type={type}/>
                        :
                            <Form type={type}/>
                        }
                    </a.article>
                })}
            </div>

            <TkDivider style={{marginTop: 50}}/>

            <div className='TkSignUpView__footer'>
                <p>{tk_var_teky_name} | {formatDocument(tk_var_teky_document)} | {tk_var_teky_address1}</p>
                <p>Formas de Pagamento: Cartão de crédito, Boleto e Pix</p>
            </div>
        </div>
    </>
}


export default memo(_SignInOrCreateAccount)
