import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {useTkDeakContext, useTkUser} from "../../context/TkContext";
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import TkReticenceLoading from "../../components/particles/TkReticenceLoading";
import {formatDocument, formatPostalCode, interactiveFormatPhone, isBlank, isCpf} from "../../utils/string-utils";
import TkLazyImage from "../../components/particles/TkLazyImage";
import {ITkProductQuantityModel} from "../../models/product";
import {formatMoney, formatNumber} from "../../utils/number-utils";
import {animated as a, useTrail} from "react-spring";
import {useHistory} from "react-router-dom";
import {toast} from 'react-toastify';
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {tk_route_my_list} from "../TkMyListsView";
import Cleave from "cleave.js";

const _StepMakeOrder: FC<{
    next: () => void;
    back: () => void;
}> = ({next, back}) => {
    const history = useHistory();
    const {isAuth, userAuth} = useTkUser()
    const {prices, customer, list, isLoadingList, makeOrder, isMakingOrder, resetState, setShipmentCost} = useTkDeakContext()
    const [errors, setErrors] = useState<string[]>([]);
    const config = {mass: 5, tension: 2000, friction: 200};
    const trail = useTrail(errors.length, {
        config,
        opacity: errors.length > 0 ? 1 : 0,
        from: {opacity: 0},
    });
    const {
        value: sellerName,
        setValue: setSellerName,
        bind: bindSellerName,
    } = useSimpleInput("");
    const [shipmentValue, setShipmentValue] = useState(0)

    const shipmentRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        let cleave: any;
        if (shipmentRef.current) {
            cleave = new Cleave(shipmentRef.current, {
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                numeralDecimalScale: 2,
                numeralDecimalMark: ',',
                delimiter: '.',
                onValueChanged(event: any) {
                    setShipmentValue(event.target.rawValue)
                    setShipmentCost(event.target.rawValue)
                },
            })
        }

        return () => {
            if (cleave) cleave.destroy();
        }
    }, [shipmentRef.current]);

    const confirmData = async (e: React.FormEvent) => {
        e.preventDefault();

        const newErrors: string[] = []
        setErrors([])

        if (isBlank(sellerName)) newErrors.push("Informe o nome do vendedor")
        if (!isAuth()) newErrors.push("Usuário não autenticado")

        if (newErrors.length === 0) {

            try {
                await makeOrder(sellerName)

                toast.success('Pedido realizado com sucesso!', {
                    onClose: () => resetState()
                })

                history.replace(tk_route_my_list(list._id))
            } catch (e) {
                toast.error('Falha ao realizar pedido! Verifique e tente novamente.')
            }
        } else {
            setErrors(newErrors)
        }
    }

    const getPrice = useCallback((item: ITkProductQuantityModel) => {
        const productPrice = prices?.[item.product._id]/100 || item.price;
        return formatMoney(productPrice);
    }, [prices])

    useEffect(() => {
        if (isAuth()) setSellerName(userAuth.user.fullName)
    }, [isAuth, userAuth]);

    if (isLoadingList) return <TkReticenceLoading label='Carregando dados'/>

    return <form onSubmit={confirmData} className='TkMakeDeakOrderView__form'>
        <h1>Lista: {list?.name}</h1>
        <h2>Cliente</h2>
        <p>
            Nome: {customer?.name}<br/>
            {isCpf(customer?.document) ? 'CPF' : 'CNPJ'}: {formatDocument(customer?.document)}<br/>
            E-mail: <a href={`mailto:${customer?.email}`}>{customer?.email}</a><br/>
            Telefone: {interactiveFormatPhone(customer?.phone)}<br/>
        </p>

        <h3>Endereço</h3>
        <p>
            Rua: {customer?.address?.location} {customer?.address?.locationNumber}<br/>
            Complemento: {customer?.address?.locationNotes}<br/>
            Bairro: {customer?.address?.neighborhoodName}<br/>
            Cidade: {customer?.address?.cityName}<br/>
            Estado: {customer?.address?.stateAcronym}<br/>
            CEP: {formatPostalCode(customer?.address?.postalCode)}<br/>
        </p>

        <h2>Produtos</h2>
        <table style={{
            width: '100%'
        }}>
            <thead>
            <tr>
                <th className='t-a-c'>Produto</th>
                <th className='t-a-c'>Quantidade</th>
                <th className='t-a-c'>Preço</th>
            </tr>
            </thead>
            <tbody>
            {list?.items.map((item) => (
                <tr key={item.product._id}>
                    <td><TkLazyImage src={item.product.thumbnail} alt={item.product.name} width={100} height={100}
                                     style={{objectFit: 'contain'}}/></td>
                    <td className='t-a-r'>{item.quantity}</td>
                    <td className='t-a-r'>{getPrice(item)}</td>
                </tr>
            ))}
            </tbody>
        </table>

        <hr className='divider'/>

        <div className="form-group">
            <label htmlFor="deakOrderSellerName">Nome do vendedor</label>
            <input type="text"
                   id='deakOrderSellerName'
                   className='form-control'
                   maxLength={100}
                   disabled={isLoadingList || isMakingOrder}
                   placeholder='Informe o nome do vendedor' {...bindSellerName}/>
        </div>

        <span className='t-a-r'>
            Frete<br/>
            <input type="text"
                    ref={shipmentRef}
                    value={formatNumber(shipmentValue)}
                    className='form-control form-control-sm TkMakeDeakOrderView__input t-a-r'
                    placeholder='Informe o valor'/>
        </span>

        {trail.map((props, index) => (
            <a.div key={index} className="alert alert-danger" style={props}>
                {errors[index]}
            </a.div>
        ))}

        <div style={{
            minHeight: '2em',
        }}>
            {isMakingOrder ? <TkReticenceLoading label='Aguarde! Finalizando pedido'/> : null}
        </div>

        <div className='TkMakeDeakOrderView__button-container'>
            <button className='btn btn-secondary'
                    onClick={back}
                    disabled={isMakingOrder || isLoadingList}
                    type='button'>Voltar
            </button>
            <button className='btn btn-primary'
                    disabled={isMakingOrder || isLoadingList}
                    type='submit'>
                {isMakingOrder ? <TkSvgIcon icon='sync-solid' className='rotate-1-seg' style={{
                    width: '1em',
                    height: '1em',
                }}/> : 'Finalizar pedido'}
            </button>
        </div>
    </form>
}


export default _StepMakeOrder