export const makeOrderFromDeakSellerMutation = `mutation($deakOrder: TkDeakOrderInput!) {
    makeOrderFromDeakSeller(deakOrder: $deakOrder)
}`

export const searchCustomerPlenobrasSellerOrderQuery = `query($document: String!) {
    searchCustomerPlenobrasSellerOrder(document: $document) {
        name document nickname phone email address {
          location locationType locationNumber locationNotes postalCode stateAcronym cityName neighborhoodName
        }
    }
}`;