import React, {memo, useCallback, useRef, useState} from "react";
import {useTkMedia} from "../../context/TkContext";
import TkLazyImage from "../../components/particles/TkLazyImage";

interface params {
  videoUrl: string;
}

const YoutubeLazyPlayer = ({ videoUrl }: params) => {
  const hitDiv = useRef();
  const { isMediaSm, isMediaXs, isMediaLg, isMediaMd } = useTkMedia();
  const videoParts = videoUrl.split("/");
  const videoId = videoParts[videoParts.length - 1];

  const getBestImage = useCallback(() => {
    if (isMediaXs) return "sd";
    else if (isMediaSm || isMediaMd) return "mq";
    else if (isMediaLg) return "hq";
    else return "maxres";
  }, [isMediaXs, isMediaSm, isMediaMd, isMediaLg]);
  const [divContent, setDivContent] = useState(
    <div onClick={() => onClick()} className="video-img-container">
      <TkLazyImage
        style={{
          width: "100%",
          height: "auto",
        }}
        width={560}
        height={315}
        src={`https://img.youtube.com/vi/${videoId}/${getBestImage()}default.jpg`}
        alt="Video institucional Plenobras"
      />
      <TkLazyImage
        src="https://teky.s3-sa-east-1.amazonaws.com/icon-play-2.png"
        alt="Play video"
        width={100}
        height={100}
        className="play-button"
      />
    </div>
  );

  const onClick = useCallback(() => {
    setDivContent(
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          frameBorder="0"
          allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  }, [setDivContent]);

  return <div ref={hitDiv}>{divContent}</div>;
};

export default memo(YoutubeLazyPlayer);
