import {ITkProductModel} from "../../models/product";
import {useCallback, useReducer, useState} from "react";
import {httpPostGraphQL} from "../../utils/http-utils";
import {
    bestSellersRecommenderQuery,
    cartBasedAssociationRecommenderQuery,
    complementRecommenderQuery,
    registerEngagementMutation,
    userBasedRecommenderQuery
} from "./queries";
import {TkEngagementFunnelEntryType} from "../../models/engagementFunnel";

enum Actions {
  load_cart_based_recommender,
  load_best_sellers_recommender,
  load_user_based_recommender,
  load_complement_recommender,
}


type State = {
  cartBasedRecommender: ITkProductModel[]
  bestSellersRecommender: ITkProductModel[]
  userBasedRecommender: ITkProductModel[]
  complementRecommender: ITkProductModel[]
}

const loadCartBasedRecommender = (products: ITkProductModel[]) => ({
  type: Actions.load_cart_based_recommender,
  payload: products
})

const loadBestSellersRecommender = (products: ITkProductModel[]) => ({
  type: Actions.load_cart_based_recommender,
  payload: products
})

const loadUserBasedRecommender = (products: ITkProductModel[]) => ({
  type: Actions.load_cart_based_recommender,
  payload: products
})

const loadComplementRecommenderAction = (products: ITkProductModel[]) => ({
  type: Actions.load_complement_recommender,
  payload: products
})


type Action = ReturnType<typeof loadCartBasedRecommender> |
  ReturnType<typeof loadBestSellersRecommender> |
  ReturnType<typeof loadUserBasedRecommender> |
  ReturnType<typeof loadComplementRecommenderAction>

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.load_cart_based_recommender:
      return {
        ...state,
        cartBasedRecommender: action.payload
      }
    case Actions.load_best_sellers_recommender:
      return {
        ...state,
        bestSellersRecommender: action.payload
      }
    case Actions.load_user_based_recommender:
      return {
        ...state,
        userBasedRecommender: action.payload
      }
    case Actions.load_complement_recommender:
      return {
        ...state,
        complementRecommender: action.payload
      }
    default:
      return state
  }
}

export interface TkRecommenderContextType extends State {
  cartBasedRecommender: ITkProductModel[]
  bestSellersRecommender: ITkProductModel[]
  userBasedRecommender: ITkProductModel[]
  complementRecommender: ITkProductModel[]
  loadCartBasedRecommender: (_id: string, quantity?: number) => Promise<ITkProductModel[]>
  loadBestSellersRecommender: (quantity: number) => Promise<ITkProductModel[]>
  loadUserBasedRecommender: () => Promise<ITkProductModel[]>
  loadComplementRecommender: (quantity: number) => Promise<ITkProductModel[]>
  isLoadingCartBasedRecommender: boolean
  isLoadingBestSellersRecommender: boolean
  isLoadingUserBasedRecommender: boolean
  isLoadingComplementRecommender: boolean
  registerEngagement: (type: TkEngagementFunnelEntryType, ...productIds: string[]) => Promise<boolean>
  isCameFromRecommender: () => boolean
}

export const TkRecommenderContext = (): TkRecommenderContextType => {
  const [{
    cartBasedRecommender,
    bestSellersRecommender,
    userBasedRecommender,
    complementRecommender,
  }, dispatch] = useReducer(reducer, {
    cartBasedRecommender: [],
    bestSellersRecommender: [],
    userBasedRecommender: [],
    complementRecommender: [],
  })
  const [isLoadingCartBasedRecommender, setLoadingCartBasedRecommender] = useState(true)
  const [isLoadingBestSellersRecommender, setLoadingBestSellersRecommender] = useState(true)
  const [isLoadingUserBasedRecommender, setLoadingUserBasedRecommender] = useState(true)
  const [isLoadingComplementRecommender, setLoadingComplementRecommender] = useState(true)
  const [lastRecommenderAttributes, setLastRecommenderAttributes] = useState({
    cart: '',
    bestSeller: '',
  })

  const productsFromRecommender = (...products: ITkProductModel[]):ITkProductModel[]  => {
    if (!products) return products;

    return products.map(p => ({...p, isCameFromRecommender: true}))
  }

  const isCameFromRecommender = useCallback(() => {
    const p = new URLSearchParams(document.location.search)

    return p.get('isCameFromRecommender') === 'true'
  }, [])

  const loadCartBasedRecommender = async (_id: string, quantity: number): Promise<ITkProductModel[]> => {
    if (lastRecommenderAttributes.cart === `${_id}${quantity}`) return cartBasedRecommender

    setLoadingCartBasedRecommender(true)
    try {
      const {data: result} = await httpPostGraphQL({
        query: cartBasedAssociationRecommenderQuery,
        variables: {_id, quantity}
      });

      if (result.errors) return Promise.reject(result.errors);

      const {data: {cartBasedAssociationRecommender}} = result;

      dispatch({
        type: Actions.load_cart_based_recommender,
        payload: productsFromRecommender(...cartBasedAssociationRecommender)
      })

      setLastRecommenderAttributes({
        ...lastRecommenderAttributes,
        cart: `${_id}${quantity}`
      })

      if (cartBasedAssociationRecommender?.length > 0)
        registerEngagement(TkEngagementFunnelEntryType.rec, ...(cartBasedAssociationRecommender as ITkProductModel[]).map(p => p._id))

      return cartBasedAssociationRecommender
    } catch (e) {
      console.error('Falha na recomendação cart based', e);
      throw e
    } finally {
      setLoadingCartBasedRecommender(false)
    }
  }

  const loadBestSellersRecommender = async (quantity: number): Promise<ITkProductModel[]> => {
    if (lastRecommenderAttributes.bestSeller === `${quantity}`) return bestSellersRecommender

    setLoadingBestSellersRecommender(true)
    try {
      const {data: result} = await httpPostGraphQL({
        query: bestSellersRecommenderQuery,
        variables: {quantity}
      });

      if (result.errors) return Promise.reject(result.errors);

      const {data: {bestSellersRecommender}} = result;

      dispatch({
        type: Actions.load_best_sellers_recommender,
        payload: productsFromRecommender(...bestSellersRecommender)
      })

      setLastRecommenderAttributes({
        ...lastRecommenderAttributes,
        bestSeller: `${quantity}`
      })

      if (bestSellersRecommender?.length > 0)
        registerEngagement(TkEngagementFunnelEntryType.rec, ...(bestSellersRecommender as ITkProductModel[]).map(p => p._id))

      return bestSellersRecommender
    } catch (e) {
      console.error('Falha na recomendação best seller', e);
      throw e
    } finally {
      setLoadingBestSellersRecommender(false)
    }
  }

  const loadUserBasedRecommender = async (): Promise<ITkProductModel[]> => {
    if (userBasedRecommender?.length > 0) return userBasedRecommender

    setLoadingUserBasedRecommender(true)
    try {
      const {data: result} = await httpPostGraphQL({
        query: userBasedRecommenderQuery
      });

      if (result.errors) return Promise.reject(result.errors);

      const {data: {userBasedRecommender}} = result;
      dispatch({
        type: Actions.load_user_based_recommender,
        payload: productsFromRecommender(...userBasedRecommender)
      })

      if (userBasedRecommender?.length > 0)
        registerEngagement(TkEngagementFunnelEntryType.rec, ...(userBasedRecommender as ITkProductModel[]).map(p => p._id))

      return userBasedRecommender
    } catch (e) {
      console.error('Falha na recomendação user based', e);
      throw e
    } finally {
      setLoadingUserBasedRecommender(false)
    }
  }

  const loadComplementRecommender = async (quantity: number): Promise<ITkProductModel[]> => {

    setLoadingComplementRecommender(true)
    try {
      const {data: result} = await httpPostGraphQL({
        query: complementRecommenderQuery,
        variables: {quantity}
      });

      if (result.errors) return Promise.reject(result.errors);

      const {data: {complementRecommender}} = result;
      dispatch({
        type: Actions.load_complement_recommender,
        payload: productsFromRecommender(...complementRecommender)
      })

      if (complementRecommender?.length > 0)
        registerEngagement(TkEngagementFunnelEntryType.rec, ...(complementRecommender as ITkProductModel[]).map(p => p._id))

      return complementRecommender
    } catch (e) {
      console.error('Falha na recomendação user based', e);
      throw e
    } finally {
      setLoadingComplementRecommender(false)
    }
  }

  const registerEngagement = async (type: TkEngagementFunnelEntryType, ...productIds: string[]): Promise<boolean> => {

    try {
      const {data: result} = await httpPostGraphQL({
        query: registerEngagementMutation,
        variables: {type, productIds, screen: document.location.href}
      });

      if (result.errors) return Promise.reject(result.errors);

      return true
    } catch (e) {
      console.error('Falha na recomendação user based', e);
      throw e
    }
  }

  return {
    cartBasedRecommender,
    bestSellersRecommender,
    userBasedRecommender,
    complementRecommender,
    isLoadingCartBasedRecommender,
    isLoadingBestSellersRecommender,
    isLoadingUserBasedRecommender,
    isLoadingComplementRecommender,
    registerEngagement,
    loadCartBasedRecommender,
    loadBestSellersRecommender,
    loadUserBasedRecommender,
    loadComplementRecommender,
    isCameFromRecommender,
  }
}
