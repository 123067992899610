import './style.scss'
import React, {FC, memo, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import TkSvgIcon from "../TkSvgIcon";
import {useTkDrawer, useTkListPro, useTkMedia, useTkUser, useTkWhatsAppBalloon} from "../../../context/TkContext";
import {tk_route_root} from "../../../views/TkHomeView";
import {tk_route_account} from "../../../views/TkMyAccountView";
import {tk_route_my_orders} from "../../../views/TkMyOrdersView";
import {tk_route_sign_in} from "../../../views/TkSignInView";
import {tk_route_about} from "../../../views/TkAboutView";
import {tk_route_pro} from "../../../views/TkProView";
import TkModalList, {ITkModalList} from "../../list/TkModalList";
import TkTopSearch from "../../product/TkTopSearch";
import TkTopWelcome from "../../user/TkTopWelcome";
import TkTopCart from "../../cart/TkTopCart";
import TkDrawer from "../TkDrawer";
import PlenoLogo from "../../../assets/images/logo-loja-plenobras-colored.svg";
import TkLazyImage from "../TkLazyImage";
import {tk_var_phone1} from "../../../views/TkBeInTouchView";

const Order: FC = () => {
  const {isMediaXs, isMediaSm} = useTkMedia();
  if (isMediaXs || isMediaSm) return <></>;
  return <Link className='Header__my-orders' to={tk_route_my_orders}>Pedidos</Link>
}

const Phone: FC = () => {
  const {isMediaXs, isMediaSm} = useTkMedia();
  if (isMediaXs || isMediaSm) return null;
  return <span className="Header__phone">
    <a href={`tel:${tk_var_phone1()}`}><TkSvgIcon icon="phone-circle-solid" className="phone-circle-solid" />  {tk_var_phone1(true)}</a>
  </span>
}

const About: FC = () => {
  const {isMediaXs, isMediaSm} = useTkMedia();
  if (isMediaXs || isMediaSm) return null;
  return <div className='Header__about'>
    <Link to={tk_route_about}>Quem Somos</Link> | <Link to={tk_route_pro}>Pro</Link>
  </div>
}

const Profile: FC = () => {
  const {isMediaXs, isMediaSm} = useTkMedia();
  const {isAuth, userAuth} = useTkUser();

  if (isMediaXs || isMediaSm) {

    return <Link
      className='Header__my-profile ellipsis'
      style={{
        justifySelf: isAuth() ? 'none' : 'end',
        marginTop: isAuth() ? 0 : 10
      }}
      title='Meu Perfil'
      to={isAuth() ? tk_route_account : `${tk_route_sign_in}?from=${encodeURIComponent(window.location.pathname)}`}
    >
      {isAuth() ? <span>Olá,<br/>{userAuth.user.fullName}!</span> : <TkSvgIcon icon='user-circle-solid'/>}
    </Link>
  }

  return <></>;
};

const TkHeader: FC = () => {
  const {setIsShow} = useTkDrawer();
  const {hasProduct, product} = useTkListPro();
  const modalListRef = useRef<ITkModalList>()
  const {setShowIcon} = useTkWhatsAppBalloon()

  useEffect(() => {
    if (hasProduct) {
      if (!modalListRef.current.isShow) modalListRef.current.show(product)
    } else modalListRef.current.hide()
  }, [modalListRef.current, hasProduct, product])

  return <>
    <header className='Header'>
      <span title='Menu de áreas' onClick={() => {
        setIsShow(true)
        setShowIcon(false)
      }} className='Header__menu-bars'>
          <TkSvgIcon icon='bars-solid'/>
      </span>
      <Link to={tk_route_root}
            className='Header__logo-container'
            title="Link para Home Page">
        <TkLazyImage
            src={PlenoLogo}
            className="Header__logo"
            alt="Plenobras"
        />
      </Link>
      <TkTopSearch/>
      <TkTopWelcome/>
      <Phone/>
      <About/>
      <Order/>
      <Profile/>
      <TkTopCart/>
    </header>
    <TkDrawer/>

    <TkModalList ref={modalListRef}/>
  </>

}

export default memo(TkHeader);
