import React, {lazy, Suspense} from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import './style.scss';
import {useLocation} from "react-router-dom";
import {tk_route_account} from "../TkMyAccountView";
import {Company} from "../../models/user";

const MyAddresses = lazy(() => import('./_myAddresses'))

const base = '/sua-conta/enderecos'

export const tk_route_my_addresses = (data?: Company) => {
    if (data?.document) return `${base}?empresa=${data.document}`
    return base;
}

const TkMyAddressesView: React.FC = () => {
    const location = useLocation();
    const p = new URLSearchParams(location.search)
    const title =  p.has('empresa') ? 'Endereços da Empresa' : 'Meus Endereços'

    return <>
        <TkHeadSEO title={`${title} | Plenobras`}/>

        <TkHeader/>

        <div className="container m-t-1rem">
            <TkBreadcrumb list={[
                {
                    url: tk_route_account,
                    label: 'Sua Conta'
                }, {
                    url: `${location.pathname}${location.search}`,
                    label: title
                }
            ]}/>
            <h1>{title}</h1>

            <Suspense fallback={<div/>}>
                <MyAddresses/>
            </Suspense>
        </div>
        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default TkMyAddressesView
