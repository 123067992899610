import {availability} from "../enums/availability-enum"
import {ITkProductModel} from "../models/product"

export const getProductAvailabilityStatus = ({ isActive, stock, leadTime, price, minimumSaleQuantity, controlMultiplicity } : ITkProductModel) => {
    if (!isActive) {
        return availability.DISCONTINUED
    }
    const isInStock = stock && stock > 0
    const hasMinimumSaleQuantity = !controlMultiplicity || (controlMultiplicity && stock >= minimumSaleQuantity)
    const isSellableItem = ((isInStock && hasMinimumSaleQuantity) || leadTime) && price
    if (!isSellableItem) {
        return availability.OUT_OF_STOCK
    }

    return stock ? availability.IN_STOCK : availability.BACKORDER

}